import * as actionTypes from "../constants";

export function updateModel(data: string): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.UPDATE_MODEL_SUCCESS,
    payload: data,
  };
}

export function getAnswer(data: any): { type: string; payload: object } {
  return {
    type: actionTypes.GET_ANSWER_REQUEST,
    payload: data,
  };
}

export function getAnswerAgain(data: any): {
  type: string;
  payload: object;
} {
  return {
    type: actionTypes.GET_ANSWER_AGAIN_REQUEST,
    payload: data,
  };
}

export function getChatHistory(chatId: string = "last"): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.GET_CHAT_HISTORY_REQUEST,
    payload: chatId,
  };
}
// export function getChatAnswer(data: any): {
// 	type: string;
// 	payload: any;
// } {
// 	return {
// 		type: actionTypes.GET_CHAT_ANSWER_REQUEST,
// 		payload: data,
// 	};
// }

export function deleteChatHistory(): { type: string } {
  return {
    type: actionTypes.DELETE_CHAT_HISTORY_REQUEST,
  };
}

export function getEmbeddings(): { type: string } {
  return {
    type: actionTypes.EMBEDDING_HISTORY_REQUEST,
  };
}
export function cleanMessages(): { type: string } {
  return {
    type: actionTypes.CLEAR_MESSAGES_HISTORY,
  };
}

export function getMycQuickResponses(callBack: any) {
  return {
    type: actionTypes.GET_MYC_QUICK_RESPONSES_REQUEST,
    callBack,
  };
}

export function updateChatStreaming(status: boolean = false): {
  type: string;
  payload: boolean;
} {
  return {
    type: actionTypes.CHAT_STREAMING,
    payload: status,
  };
}
