import UserService from "../KeyCloackServices/UserService";

export const decode = (token: any): any => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [encodedHeader, encodedPayload, signature] = token.split(".");
  const payload = JSON.parse(atob(encodedPayload));

  return payload.data;
};

export const getAccessToken = (): string | null => {
  return `Bearer ${UserService.getToken()}`;
};
// export const getAccessToken = (): string | null => {
//   return localStorage.getItem("accessToken");
// };

export const getBotAccessToken = (): string | null => {
  return localStorage.getItem("botAccessTokenEncoded");
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbmNyeXB0ZWQiOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKcFpDSTZNamsyTENKbGJXRnBiQ0k2SW5Ob1lXdHBja0JvYkdSeWFYWmxMbU52YlNJc0ltbGhkQ0k2TVRZNU9URXdNak0wTml3aVpYaHdJam96TnpFek5UWTBOamt5ZlEuekxHel9pR1pXWkt0Y2habGZPTmk2SmxTOE9xdElkcEp1Q0ZfWUMtN2ZCayIsImlhdCI6MTY5OTEwMjM0NiwiZXhwIjozNzEzNTY0NjkyfQ.Cm8IcnWwIfC00BYT1SuwnI-5aGifSlFKM3wlI1nWhnE";
};

export const getBotUniqueUserId = (): string | null => {
  return localStorage.getItem("botUniqueUserId");
};
