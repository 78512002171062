import { useState } from "react";
import { RiLinkM } from "react-icons/ri";
import { useSelector } from "react-redux";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FiShare2 } from "react-icons/fi";
import { Divider } from "@mui/material";
import { IoLogoWhatsapp } from "react-icons/io";
import CopyToClipboard from "react-copy-to-clipboard";

const CopyGptLink = () => {
  const { botDetails } = useSelector((state) => state.bot);
  const [show, setShow] = useState(false);
  const [clipboardText, setClipboardText] = useState("Click to copy");

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <span
        className="flex justify-start items-center gap-2 cursor-pointer mb-2 "
        onClick={handleShow}
      >
        <FiShare2 className="text-base" />
        Share
      </span>
      {show && (
        <>
          <Divider className="my-2" />
          <div className="mt-2">
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => {
                setClipboardText("Copied");
              }}
            >
              <span className="flex justify-start items-center gap-2 cursor-pointer ">
                <RiLinkM className="text-base" />
                {clipboardText}
              </span>
            </CopyToClipboard>

            <a
              href={`https://wa.me/?text=Sign%20up%20for%20EntheoGPT%21%20-%20${encodeURI(
                window.location.href
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="flex justify-start items-center gap-2 cursor-pointer my-2 ">
                <IoLogoWhatsapp className="text-lg text-[#46c254]" /> Send on
                Whatsapp
              </span>
            </a>
            <a
              class="btn btn-lg btn-social btn-facebook "
              href={`https://www.facebook.com/sharer.php?u=${encodeURI(
                window.location.href
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="flex justify-start items-center gap-2 cursor-pointer my-2">
                <FaFacebook className="text-lg text-[#4d69a2]" /> Share on
                Facebook
              </span>
            </a>
            <a
              class="btn btn-lg btn-social btn-facebook "
              href={`https://twitter.com/intent/tweet?url=${encodeURI(
                window.location.href
              )}%26amp%3Btext%3DSign%20up%20for%20EntheoGPT!`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="flex justify-start items-center gap-2 cursor-pointer my-2">
                <FaTwitter className="text-lg text-[#66b4f0]" /> Tweet on
                Twitter
              </span>
            </a>
            <a
              href={`mailto:?subject=Check%20out%20EntheoGPT%21&amp;body=I%20just%20signed%20up%20for%20EntheoGPT%20and%20thought%20you%20might%20be%20interested%2C%20too%21%0A%0A ${encodeURI(
                window.location.href
              )}`}
              target="_top"
            >
              <span className="flex justify-start items-center gap-2 cursor-pointer my-2">
                <IoMail className="text-lg text-[#8a8a8a]" /> Send Via Mail
              </span>
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default CopyGptLink;
