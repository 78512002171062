import { useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Dialog,
  DialogBody,
  Typography,
  DialogHeader,
} from "@material-tailwind/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { cleanMessages, deleteChatHistory } from "../../store/messages/actions";
import WarningIcon from "@mui/icons-material/Warning";
import { Divider } from "@mui/material";
import { getSettingData } from "../sidebar";
import ProfileSetting from "../SettingsPages/profileSetting";
import CloseIcon from "@mui/icons-material/Close";
import { logout } from "../../store/auth/actions";
import { useLocation, useNavigate } from "react-router-dom";

export function DeleteChatHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const sections = getSettingData();
  const location = useLocation();

  const deleteHandler = () => {
    dispatch(deleteChatHistory());
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleMenu = (text) => {
    setOpen(!open);
    if (text === "Profile") {
      setIsProfile(true);
      setOpen(!open);
    } else {
      setIsProfile(false);
      setOpen(false);
    }
  };

  const onClickHandler = (path) => {
    if (typeof path === "string") {
      return () => {
        navigate(path);
      };
    } else {
      return () => {
        // alert("here");
        dispatch(cleanMessages());
        dispatch(logout());
      };
    }
  };
  const handleLogout = () => {
    dispatch(cleanMessages());
    dispatch(logout());
  };
  return (
    <>
      <Menu placement="top-end">
        <MenuHandler>
          <MoreVertIcon className="cursor-pointer" />
        </MenuHandler>

        <MenuList className="!w-auto p-0">
          {location.pathname === "/chat" && (
            <MenuItem
              onClick={handleOpen}
              className="flex justify-start items-center gap-3 "
            >
              <DeleteIcon className="text-red-500 !text-base" />
              <Typography variant="small" className="!text-sm text-red-500">
                Clear Chat
              </Typography>
            </MenuItem>
          )}

          <Divider />
          {sections.map((_item) => {
            return (
              <>
                {_item?.item?.map((_item, idx) => (
                  <>
                    {_item.title === "Profile" ? (
                      <MenuItem
                        key={idx}
                        onClick={() => {
                          handleMenu(_item.title);
                        }}
                        className="flex justify-start items-center gap-3 "
                      >
                        {/* <DeleteIcon className="text-red-500 !text-base" /> */}
                        {_item.icon}
                        <Typography variant="small" className="!text-sm ">
                          {_item.title}
                        </Typography>
                      </MenuItem>
                    ) : (
                      <>
                        {_item.title !== "Email" && (
                          <MenuItem
                            key={idx}
                            onClick={() => {
                              _item?.title === "Logout"
                                ? handleLogout()
                                : onClickHandler(_item.path);
                            }}
                            className="flex justify-start items-center gap-3 "
                          >
                            {/* <DeleteIcon className="text-red-500 !text-base" /> */}
                            {_item.icon}
                            <a
                              rel="noreferrer"
                              href={_item?.link ? _item?.link : "#"}
                              target={_item?.link ? "_blank" : "_self"}
                            >
                              <Typography variant="small" className="!text-sm ">
                                {_item.title}
                              </Typography>
                            </a>
                            {_item?.setting && _item?.setting}
                          </MenuItem>
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            );
          })}
        </MenuList>
      </Menu>

      <Dialog size={isProfile ? "md" : "xs"} open={open} handler={handleOpen}>
        {isProfile && (
          <DialogHeader className="flex justify-between items-center text-xl">
            {"Profile"}
            <CloseIcon onClick={handleOpen} className="cursor-pointer" />
          </DialogHeader>
        )}

        <DialogBody
          divider
          className="grid place-items-start  max-h-[40rem] overflow-scroll overflow-x-hidden"
        >
          {isProfile ? (
            <ProfileSetting />
          ) : (
            <>
              <Typography color="black" variant="lead">
                <WarningIcon className="text-red-500" /> Confirmation
              </Typography>
              <Typography className="text-center font-normal">
                Are you sure you want to delete your chat?
              </Typography>
              <div className="w-full flex justify-end items-end">
                <Button
                  size="sm"
                  variant="text"
                  color="blue-gray"
                  className=" normal-case font-medium"
                  onClick={handleOpen}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant="text"
                  onClick={deleteHandler}
                  className="text-red-500 normal-case font-medium"
                >
                  Delete
                </Button>
              </div>
            </>
          )}
        </DialogBody>
      </Dialog>
    </>
  );
}
