import { useEffect, useState } from "react";
import http from "../../services/http-common";
import { setSessionExpired, useMainController } from "../../context";
import { useMutation } from "react-query";
import * as messages from "../../services/messages";
import { toast } from "react-toastify";
import {
  Typography,
  Tooltip,
  IconButton,
  Button,
  Input,
} from "@material-tailwind/react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import EditFaqs from "./EditFaqs";
import { useTheme } from "@mui/material";

const BotSuggestions = () => {
  const theme = useTheme();
  const [dispatch] = useMainController();
  const [faqs, setFaqs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [faqContent, setFaqContent] = useState({
    question: "",
    answer: "",
  });

  const errorFunc = (error) => {
    let message;
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const getPrompt = useMutation(
    () => {
      return http.get(
        "user/quick-replies/getAll",
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        setFaqs(data.quickReplies);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const getFaqs = () => {
    getPrompt.mutate();
  };

  const deleteFaq = useMutation(
    async (faqId) => {
      return await http.deleteFaqs("user/quick-replies/delete", {
        userId: {
          id: faqId,
        },
      });
    },
    {
      onSuccess: () => {
        toast.success("Successfully Deleted!");
        getFaqs();
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  const onDeleteFaq = (id) => {
    deleteFaq.mutate(id);
  };

  const onChangeContent = (e) => {
    if (loading) return false;
    setFaqContent({ ...faqContent, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    saveContent.mutate();
  };

  const saveContent = useMutation(
    () => {
      return http.post(`user/quick-replies/create`, faqContent, null);
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Successfully Added!");
        getFaqs();
        setFaqContent({
          question: "",
          answer: "",
        });
      },
      onError: (error) => {
        errorFunc(error);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    getFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="mt-5 flex w-full flex-col gap-4">
          <div className="flex flex-col gap-6">
            <Typography
              color={theme.palette.mode === "dark" ? "white" : "gray"}
              variant="h6"
            >
              Please enter suggestion:
            </Typography>
            <div className="flex h-full w-full flex-row items-center gap-6">
              <Input
                name="question"
                type="text"
                label="Question"
                value={faqContent?.question}
                onChange={onChangeContent}
                className={`px-3 py-[0.32rem] !border !border-gray-300 ${
                  theme.palette.mode === "dark"
                    ? "text-white"
                    : " focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                } shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 `}
                labelProps={{
                  className:
                    "before:content-none after:content-none px-2 !text-white",
                }}
              />
              <Button
                variant="gradient"
                size="lg"
                color={theme.palette.mode === "dark" && "white"}
                className="flex items-center gap-3 py-2 px-4"
                disabled={loading}
                onClick={onSubmit}
              >
                <FaPlus /> ADD
              </Button>
            </div>
          </div>
          <div className="mt-5">
            {faqs && (
              <table className="mt-4 w-full min-w-max table-auto text-left">
                <tbody>
                  {faqs?.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td
                          className={
                            theme.palette.mode === "dark"
                              ? " border border-b-0 border-l-0 border-r-0 !border-t-[#3b4357]"
                              : "p-4 border-b border-t border-blue-gray-50 "
                          }
                        >
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className={`font-normal ${
                                  theme.palette.mode === "dark" && "text-white"
                                }`}
                              >
                                <span className={`font-bold`}>Question: </span>
                                {item.question}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td
                          className={
                            "flex justify-end items-center p-4 border-b border-t border-blue-gray-50 "
                          }
                        >
                          <EditFaqs id={item.id} getFaqs={getFaqs} />
                          <Tooltip content="Delete">
                            <IconButton
                              variant="text"
                              onClick={() => onDeleteFaq(item.id)}
                            >
                              <FaTrashAlt
                                color={
                                  theme.palette.mode === "dark" && "#e70b25"
                                }
                                className={`h-5 w-5 `}
                              />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BotSuggestions;
