import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Select,
  Option,
  Chip,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Input,
  Tooltip,
  IconButton,
  Progress,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import http from "../../../services/http-common";
import {
  CloudArrowUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { GiCycle } from "react-icons/gi";
import { useMainController, setSessionExpired } from "../../../context";
import { formatDate } from "../../../lib/util";
import Table from "../../../widgets/table";
import * as messages from "../../../services/messages";
import { FaCheckDouble, FaLock, FaUnlock, FaTrashAlt } from "react-icons/fa";
import EditMetaContent from "../../EditMetaContent";
import { useTheme } from "@mui/material";
import { toast } from "react-toastify";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

export function TrainBook() {
  const [dispatch] = useMainController();
  const theme = useTheme();
  const [contentId, setContentId] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const queryClient = useQueryClient();

  const cp = queryClient.getQueryData("books-cp");
  const ps = queryClient.getQueryData("books-ps");
  const filters = queryClient.getQueryData("books-filters");
  const sorters = queryClient.getQueryData("books-sorters");

  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
  });
  const [books, setBooks] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("title");
  const [checkValues, setCheckValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(cp || 1);
  const [pageSize, setPageSize] = useState(ps || 50);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [totalPage, setTotalPage] = useState(10000);
  const [filterValues, setFilterValues] = useState(filters || {});
  const [sorterValues, setSorterValues] = useState(
    sorters || { updated_at: { desc: true } }
  );

  useQuery("books-cp", () => currentPage);
  useQuery("books-ps", () => pageSize);
  useQuery("books-filters", () => filterValues);
  useQuery("books-sorters", () => sorterValues);

  const tableHeaders = [
    {
      key: "name",
      name: "name",
      // filter: {
      //   type: "search",
      // },
      sorter: true,
    },
    {
      key: "userName",
      name: " user",
      width: "30%",
      // filter: {
      //   type: "search",
      // },
      sorter: true,
    },
    {
      key: "status",
      name: "status",
      width: "20%",
      filter: {
        type: "check",
        kinds: [
          { name: "ready", value: "ready" },
          { name: "pending", value: "pending" },
          { name: "pending failed", value: "pending failed" },
          { name: "Training Failed", value: "training_failed" },
          { name: "done", value: "done" },
          { name: "rejected", value: "rejected" },
        ],
      },
      sorter: true,
    },
    {
      key: "private",
      name: "private",
      width: "20%",
      // filter: {
      //   type: "radio",
      //   kinds: [
      //     { name: "private", value: 1 },
      //     { name: "public", value: 0 },
      //   ],
      // },
      sorter: true,
    },
    { key: "updated_at", name: "update at", sorter: true },
    {
      key: "actions",
      name: "",
      default: (id) => (
        <Menu
          animate={{
            mount: { y: 0 },
            unmount: { y: 25 },
          }}
        >
          <MenuHandler>
            <Button
              className={`
            ${theme.palette.mode === "dark" && "!bg-[#f3f6ff] text-[#1a2035]"}`}
            >
              Action
            </Button>
          </MenuHandler>
          <MenuList
            className={
              theme.palette.mode === "dark" && "bg-[#1a2035] text-[#f3f6ff]"
            }
          >
            <MenuItem
              onClick={() => {
                handleTrain(id);
              }}
            >
              Train
            </MenuItem>
            {theme.palette.mode === "dark" && (
              <hr className="m-0 !border-t-[#3b4357]" />
            )}
            <MenuItem
              onClick={() => {
                handleUnTrain(id);
              }}
            >
              Untrain
            </MenuItem>
            {theme.palette.mode === "dark" && (
              <hr className="m-0 !border-t-[#3b4357]" />
            )}
            <MenuItem
              onClick={() => {
                updatePrivate(id);
              }}
            >
              {/*{books.find((book) => book.id === id).private === true*/}
              {/* eslint eqeqeq: 0 */}
              {books.find((book) => book.id === id).private?.props?.value ==
              "private"
                ? "Make public"
                : "Make private"}
            </MenuItem>
            {theme.palette.mode === "dark" && (
              <hr className="m-0 !border-t-[#3b4357]" />
            )}
            <MenuItem
              onClick={() => {
                deleteFunc(id);
              }}
            >
              Delete
            </MenuItem>
            {theme.palette.mode === "dark" && (
              <hr className="m-0 !border-t-[#3b4357]" />
            )}
            <MenuItem
              onClick={() => {
                handleEdit(id);
              }}
            >
              Edit Meta Data
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ];

  const errorFunc = (error) => {
    let message;
    switch (error.response.status) {
      case 500: {
        message = error.response.data.message || messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const { isError, error, data } = useQuery(
    ["books", currentPage, pageSize, filterValues, sorterValues],
    () => {
      if (
        filterValues[selectedFilter] &&
        filterValues[selectedFilter].length > 0
      ) {
        return http.post(
          `train/book/search`,
          {
            cp: currentPage,
            ps: pageSize,
            filters: {
              [selectedFilter]: filterValues[selectedFilter],
            },
          },
          setSessionExpired,
          dispatch
        );
      } else {
        return http.get(
          `train/book/all?cp=${currentPage}&ps=${pageSize}${
            JSON.stringify(filterValues) !== "{}"
              ? `&filters=${JSON.stringify(filterValues)}`
              : ""
          }${
            JSON.stringify(sorterValues) !== "{}"
              ? `&sorters=${JSON.stringify(sorterValues)}`
              : ""
          }`,
          null,
          setSessionExpired,
          dispatch
        );
      }
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
      queryKey: ["books", currentPage, pageSize], // Unique key for the query
      staleTime: 30000, // Keep data in cache for 30 seconds
      retryOnMount: true,
    }
  );

  useEffect(() => {
    if (isError) {
      console.log(error);
      toast.error("Fetch data failed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (data && data.data) {
      const { docs, pageSize, total } = data.data;
      const newDocs = docs.map((doc) => {
        return {
          ...doc,
          key: doc.id,
          name: (
            <Tooltip
              content={doc.name}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <a href={doc.originalUrl} target="_blank" rel="noreferrer">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className={`font-semibold ${
                    theme.palette.mode === "dark" && "text-[#cdcdcd]"
                  }`}
                >
                  {doc.name.length > 40
                    ? doc.name.slice(0, 40) + "..."
                    : doc.name}
                </Typography>
              </a>
            </Tooltip>
          ),
          userName: (
            <Typography
              variant="small"
              color="blue-gray"
              className={`font-semibold ${
                theme.palette.mode === "dark" && "text-[#cdcdcd]"
              }`}
            >
              {doc.uploadedBy ? doc.uploadedBy : doc.user?.firstName}
            </Typography>
          ),
          updated_at: (
            <Typography
              variant="small"
              color="blue-gray"
              className={`font-semibold ${
                theme.palette.mode === "dark" && "text-[#cdcdcd]"
              }`}
            >
              {formatDate(doc.updated_at)}
            </Typography>
          ),
          status: (
            <Chip
              icon={
                doc.status === "rejected" && (
                  <Tooltip
                    content={doc?.rejectionReason}
                    animate={{
                      mount: { scale: 1, y: 0 },
                      unmount: { scale: 0, y: 25 },
                    }}
                  >
                    <ExclamationCircleIcon className="bg-[white] rounded-full text-[black] m-[1px]" />
                  </Tooltip>
                )
              }
              variant="gradient"
              color={status(doc.status)}
              value={doc.status}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
          private: (
            <Chip
              variant="gradient"
              color={doc.private ? "red" : "blue"}
              value={doc.private ? "private" : "public"}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
        };
      });
      setBooks(newDocs);

      setTotalPage(Math.ceil(total / pageSize));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      // console.log(`${loaded}kb of ${total}kb | ${percent}%`)

      if (percent < 100) {
        setUploadPercentage(percent);
      } else {
        setUploadPercentage(0);
      }
    },
  };

  const fileUpload = useMutation(
    async (file) => {
      return http.filepost(
        `train/book`,
        file,
        options,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        reset();
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );

  const onSubmit = (data) => {
    if (data.books.length !== 0) {
      const formData = new FormData();
      for (let i = 0; i < data.books.length; i++) {
        formData.append("books", data.books[i]);
      }
      fileUpload.mutate(formData);
    } else {
      toast.error("Please select any file");
    }
  };

  const startTrain = useMutation(
    (id) => {
      return http.post(
        "train/book/bulk/train",
        { ids: [id] },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );
  const startUnTrain = useMutation(
    (docId) => {
      return http.deleteDocFrompinecone(`train/book/delete-from-pinecone`, {
        id: docId,
      });
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const handleTrain = (id) => {
    const val = data.data.docs.find((book) => book.id === id);
    if (val.status == "done") {
      toast.success("Already trained");
      return;
    } else if (val.status == "pending") {
      toast.success("Training Now");
      return;
    }
    startTrain.mutate(val.id);
  };
  const handleUnTrain = (id) => {
    startUnTrain.mutate(id);
  };

  const changePrivate = useMutation(
    (val) => {
      return http.put(
        `train/book/${val.id}/private`,
        { private: val.private === true ? "public" : "private" },
        null
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );

  const updatePrivate = (id) => {
    const val = data.data.docs.find((book) => book.id === id);
    changePrivate.mutate(val);
  };
  const deleteData = useMutation(
    (id) => {
      return http.delete(`train/book/${id}`, null);
    },
    {
      onSuccess: () => {
        toast.success("Successfully Deleted");
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );

  const deleteFunc = (id) => {
    deleteData.mutate(id);
  };

  const status = (value) => {
    switch (value) {
      case "ready": {
        return "blue";
      }
      case "pending": {
        return "purple";
      }
      case "done": {
        return "green";
      }
      case "pending failed": {
        return "red";
      }
      case "rejected": {
        return "red";
      }
      case "training_failed": {
        return "pink";
      }
      default: {
      }
    }
  };

  const bulkTrainRequest = useMutation(
    () => {
      return http.post(
        "train/book/bulk/train",
        { ids: checkValues },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        setCheckValues([]);
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );
  const bulkTrain = () => {
    bulkTrainRequest.mutate();
  };

  const bulkPrivateRequest = useMutation(
    (val) => {
      return http.put(
        "train/book/bulk/private",
        { private: val, ids: checkValues },
        null
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        setCheckValues([]);
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );
  const bulkPrivate = () => {
    bulkPrivateRequest.mutate("private");
  };
  const bulkPublic = () => {
    bulkPrivateRequest.mutate("public");
  };
  const bulkDeleteRequst = useMutation(
    () => {
      return http.post(
        "train/book/bulk/delete",
        { ids: checkValues },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        setCheckValues([]);
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );
  const bulkDelete = () => {
    bulkDeleteRequst.mutate();
  };
  const bulkReAttemptRequst = useMutation(
    () => {
      let arrCheckedValues = checkValues.map(Number);
      return http.post(
        `/train/book/re-attempt`,
        { ids: arrCheckedValues },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        setCheckValues([]);
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );
  const bulkReAttempt = () => {
    bulkReAttemptRequst.mutate();
  };

  const actions = (
    <>
      <Tooltip content="Reprocess">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={bulkReAttempt}
        >
          <GiCycle />
        </IconButton>
      </Tooltip>
      <Tooltip content="train">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={bulkTrain}
        >
          <FaCheckDouble />
        </IconButton>
      </Tooltip>
      <Tooltip content="private">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={bulkPrivate}
        >
          <FaLock />
        </IconButton>
      </Tooltip>
      <Tooltip content="public">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={bulkPublic}
        >
          <FaUnlock />
        </IconButton>
      </Tooltip>
      <Tooltip content="delete">
        <IconButton
          variant="text"
          className="rounded-full"
          color="red"
          onClick={bulkDelete}
        >
          <FaTrashAlt />
        </IconButton>
      </Tooltip>
    </>
  );

  const onChangeCheckValues = (values) => {
    setCheckValues(values);
  };

  const onChangePagination = (cp, ps) => {
    setCurrentPage(cp);
    setPageSize(ps);

    queryClient.setQueryData("books-cp", cp);
    queryClient.setQueryData("books-ps", ps);
  };

  const onChangeFilter = (filterValues) => {
    setFilterValues(filterValues);
    queryClient.setQueryData("books-filters", { ...filterValues });
  };

  const onChangeSorter = (sorterValues) => {
    setSorterValues({ ...sorterValues });
    queryClient.setQueryData("books-sorters", { ...sorterValues });
  };

  const onSearchFileName = (data) => {
    let selectedFilterVar = selectedFilter;

    setFilterValues((vals) => {
      return {
        ...vals,
        [selectedFilterVar]: data.name,
      };
    });
  };

  const toogleOpenEditor = () => {
    if (editorOpen === true) {
      setContentId(null);
    }
    setEditorOpen(!editorOpen);
  };

  const handleEdit = (id) => {
    setContentId(id);
    toogleOpenEditor();
  };

  const onChageSelectedFilter = (val) => {
    setSelectedFilter(val);
  };

  window.document.title = "MushGpt | Admin | Documents";

  return (
    <>
      <div className="mt-4 mb-8 flex flex-col gap-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            color={theme.palette.mode === "dark" ? "white" : "gray"}
            variant="h6"
          >
            Upload Document
          </Typography>
          <div className="flex h-full w-full flex-row items-center gap-6 mt-2">
            <input
              className="border-neutral-300 text-neutral-700 file:bg-neutral-100 file:text-neutral-700 hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary relative m-0 block w-full min-w-0 flex-auto rounded border border-solid bg-clip-padding px-3 py-[0.32rem] text-base font-normal transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:px-3 file:py-[0.32rem] file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] focus:outline-none"
              type="file"
              id="formFile"
              name="books"
              {...register("books")}
              multiple
            />
            <Button
              variant="gradient"
              color={theme.palette.mode === "dark" && "white"}
              className={`flex items-center gap-3 py-2 px-3  `}
              type="submit"
              disabled={fileUpload.isLoading}
            >
              <CloudArrowUpIcon strokeWidth={2} className="h-5 w-5" /> Upload
            </Button>
          </div>
          {uploadPercentage > 0 && uploadPercentage < 100 && (
            <Progress
              value={uploadPercentage}
              active
              label={`Completed`}
              className="my-3"
            />
          )}
        </form>
        <Card
          className={` ${theme.palette.mode === "dark" && "!bg-[#202940]"}`}
        >
          <CardHeader
            variant="gradient"
            className={`mb-8 p-6 ${
              theme.palette.mode === "dark" ? "!bg-[#2b344a]" : "!bg-[#2b2b2b]"
            }`}
          >
            <Typography variant="h6" color="white">
              All Documents
            </Typography>
          </CardHeader>
          <CardBody
            className={`overflow-x-auto px-2 pt-0 pb-2 ${
              theme.palette.mode === "dark" && "!bg-[#202940]"
            } `}
          >
            <div className="flex w-full justify-end py-1 pr-2">
              <form onSubmit={handleSubmit(onSearchFileName)}>
                <div className="flex">
                  <div className="flex items-center gap-2">
                    <div className="min-w-[100px] max-w-[110px] first:[&>div]:sm:!min-w-[100px] first:[&>div]:min-w-auto">
                      <Select
                        label="Search by"
                        labelProps={{
                          className:
                            theme.palette.mode === "dark" && "text-[#9e9e9e]",
                        }}
                        className={`min-w-[100px] max-w-[110px]  ${
                          theme.palette.mode === "dark" && "text-white"
                        }`}
                        value={selectedFilter}
                        onChange={onChageSelectedFilter}
                      >
                        <Option value="originalUrl">URL</Option>
                        <Option value="name">Name</Option>
                        <Option value="title">Title</Option>
                        <Option value="author">Author</Option>
                        <Option value="abstract">Abstract</Option>
                      </Select>
                    </div>
                    <Input
                      icon={
                        <MagnifyingGlassIcon
                          onClick={handleSubmit(onSearchFileName)}
                          className={`h-4 w-4 cursor-pointer ${
                            theme.palette.mode === "dark" && "text-white"
                          }`}
                        />
                      }
                      placeholder="Search"
                      className={`!border !border-gray-300 ${
                        theme.palette.mode === "dark"
                          ? "text-white"
                          : " focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                      } shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 `}
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      containerProps={{ className: "min-w-[100px]" }}
                      {...register("name")}
                    />
                  </div>
                </div>
              </form>
            </div>
            <Table
              tableHeaders={tableHeaders}
              data={books}
              paginationPosition="both"
              pagination={{
                pageSizeKind: [
                  { name: "50 items per page", value: 50 },
                  { name: "100 items per page", value: 100 },
                  { name: "150 items per page", value: 150 },
                  { name: "200 items per page", value: 200 },
                ],
                totalPage: totalPage,
                currentPage: currentPage,
                pageSize: pageSize,
              }}
              checkable
              actions={actions}
              onChangeCheckValues={onChangeCheckValues}
              checkData={checkValues}
              onChangePagination={onChangePagination}
              onChangeFilter={onChangeFilter}
              filters={filterValues}
              onChangeSorter={onChangeSorter}
              sorters={sorterValues}
            />
          </CardBody>
        </Card>
      </div>

      <EditMetaContent
        toogleOpenEditor={toogleOpenEditor}
        id={contentId}
        open={editorOpen}
      />
    </>
  );
}

export default TrainBook;
