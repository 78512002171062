import { Grid, Typography, Box } from "@mui/material";

import useStyles from "./styles/styles";
import BotImg from "../../assets/images/LLM-concept.png";
import Shape from "./components/Shape";

const AIIntro = () => {
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7.63vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: "3vw",
      py: "1vw",
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    header4: {
      fontSize: { xs: "5.09vw !important", md: "2.08vw !important" },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
      color: "#101010 !important",
      textAlign: "center",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      textAlign: "center",
      // textAlign: { xs: 'center', md: 'left' },
      py: "1vw",
    },
    buttonSx: {
      width: { md: "13.22vw", xs: "66.16vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
      my: { xs: "3vw", md: "0" },
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };
  return (
    <Grid className={classes.sectionBox} sx={{ background: "#ffffff" }}>
      <Shape secNumber="1" />
      <Grid
        item
        container
        xs={12}
        md={12}
        className={classes.flexCenter}
        sx={{
          flexDirection: "column",
          py: { xs: "15.77vw", md: "3vw" },
        }}
      >
        <Typography sx={clases.header2}>What's AI? </Typography>
        <Grid
          item
          xs={11}
          md={8.7}
          className={classes.flexCenter}
          sx={{
            flexDirection: "column",
          }}
        >
          <Grid item xs={11} md={10}>
            <Typography sx={clases.contentText}>
              Artificial Intelligence (AI) revolutionizes technology by using
              vast datasets to train Large Language Models (LLMs) such as
              ChatGPT, simulating human intelligence skills like learning,
              reasoning, and contextual understanding. This capability to
              interpret context distinctly sets AI apart from simple internet
              searches, providing users with streamlined access to knowledge and
              reducing work redundancies effectively. This distinction
              emphasizes AI's role in enhancing efficiency and accessibility in
              information processing and decision-making tasks.
            </Typography>
          </Grid>
          <Box
            component="img"
            src={BotImg}
            alt="logo"
            className={`${classes.image} `}
            sx={{ py: { md: "0", xs: "17.3vw" } }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AIIntro;
