import React from 'react';
import '../../../assets/main.css';

function YoutubePlayer() {
	const url = `https://www.youtube.com/embed/HrHI3yyaIJg?autoplay=1&mute=1`;
	return (
		<iframe
			className="youtube"
			src={url}
			title="MushGPT"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		></iframe>
	);
}

export default YoutubePlayer;
