export const formatResponse = (text) => {
  text = text?.replace(/(\d+\.[^\d])/g, "<br>$1");
  // Convert bold text with Tailwind CSS
  text = text?.replace(/"(.*?)"/g, '<strong class="font-semibold">$1</strong>');
  // text = text?.replace(/'(.*?)'/g, '<strong class="font-semibold">$1</strong>');

  // // Convert headings with Tailwind CSS
  text = text?.replace(
    // /^(\d+)\.\s\*\*(.*?)\*\*:/gm,
    /(\d+\.\s)\*\*([^-]+)\*\* /g,
    '<h2 class="text-lg font-bold my-4">$1 $2</h2>'
  );
  // // Convert headings with Tailwind CSS
  text = text?.replace(
    // /(\d+\.\s[^-]+-|\d+\.\s\*\*[\w\s\-]+?\*\*:)/g,
    // eslint-disable-next-line no-useless-escape
    /(\d+\.\s)\*\*([\w\s\-\'\.\,]+?)\*\*:/g,
    '<h2 class="text-lg font-bold my-4">$1 $2</h2>'
  );

  // // Convert ordered lists with Tailwind CSS only for lines starting with "-" but not followed by "**"
  text = text?.replace(
    /\[\d\] \*\*(.*?)\*\*:/g,
    '<li class="list-decimal list-inside font-bold">$1</li>'
  );
  text = text?.replace(
    /(<li class="list-decimal list-inside ">.*<\/li>)/g,
    '<ol class="mb-4">$1</ol>'
  );

  // Enlis details
  text = text?.replace(/\d+\. (".*") (by .*)$/g, "- $1 $2");

  // Convert bold text with Tailwind CSS
  text = text?.replace(
    /\*\*(.*?)\*\*/g,
    '<strong class="font-semibold">$1</strong>'
  );

  // Convert bold text with Tailwind CSS
  text = text?.replace(/(\- +)/g, "<br>");

  // // Replace ### with bold headings
  // text = text?.replace(
  //   /### (.*?)(\n|$)/g,
  //   `<h2 class="text-lg font-bold my-4">$1</h2>\n`
  // );

  // // Convert bold text with Tailwind CSS
  // text = text?.replace(
  //   /\*\*(.*?)\*\*/g,
  //   '<strong class="font-semibold">$1</strong>'
  // );

  // // Convert italic text with Tailwind CSS
  // text = text?.replace(/\*(.*?)\*/g, '<em class="italic">$1</em>');

  // // Convert unordered lists with Tailwind CSS only for lines starting with "-" but not followed by "**"
  // text = text?.replace(
  //   /- (?!\*\*)(.*?)$/gm,
  //   '<li class="list-disc list-inside ml-4">$1</li>'
  // );
  // text = text?.replace(
  //   /(<li class="list-disc list-inside ml-4">.*<\/li>)/g,
  //   '<ul class="mb-4">$1</ul>'
  // );

  // // Convert blockquotes with Tailwind CSS
  // text = text?.replace(
  //   /^> (.*?)$/gm,
  //   '<blockquote class="border-l-4 border-gray-500 pl-4 italic my-4">$1</blockquote>'
  // );

  // // Convert code blocks with Tailwind CSS
  // text = text?.replace(
  //   /```(.*?)```/gs,
  //   '<pre class="bg-gray-100 p-4 rounded my-4"><code>$1</code></pre>'
  // );

  // // Convert inline code with Tailwind CSS
  // text = text?.replace(
  //   /`(.*?)`/g,
  //   '<code class="bg-gray-200 px-1 rounded">$1</code>'
  // );

  // Return formatted text
  return text;
};
