import React from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  // Link
} from "@mui/material";
// import { useNavigate } from 'react-router-dom';

import useStyles from "./styles/styles";

import Shape from "./components/Shape";
import YoutubePlayer from "./components/YoutubePlayer";
import UserService from "../../KeyCloackServices/UserService";

const Entheogens = () => {
  // const navigate = useNavigate();
  const classes = useStyles();

  const clases = {
    header3: {
      fontSize: { xs: "7.5vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      my: { xs: "5vw", md: "0" },
      // textAlign: { xs: 'center', md: 'left' },
    },
    buttonSx: {
      width: { md: "13.22vw", xs: "66.16vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
      my: { xs: "3vw", md: "0" },
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
    },
  };
  return (
    <Grid
      container
      className={classes.sectionBox}
      sx={{
        backgroundColor: "#f4f6f8",
      }}
    >
      <Shape secNumber="2" />

      <>
        <Box
          className={classes.shapeBox}
          sx={{
            display: { xs: "flex", md: "none", flexDirection: "column" },
            width: "100%",
          }}
        >
          <Box
            component="img"
            src={"/images/home/sec2_shape.png"}
            alt="logo"
            sx={{
              width: "100%",
            }}
          />
        </Box>
      </>

      <Grid container className={classes.containerGrid} sx={{ pb: "3.1" }}>
        <Grid item md={0.3}></Grid>
        <Grid item xs={11} md={4.7}>
          <Typography sx={clases.header3}>
            The #1 Cause of Difficult Entheogenic Journeys is Poor Preparation!
          </Typography>
          <Box>
            <Typography sx={clases.contentText}>
              EntheoGPT is your AI-powered research assistant—ready to assist
              with just a question, as easy as texting a friend! It provides
              answers on entheogens, neuroscience, and new healing practices. It
              can also book coaching sessions, understands multiple languages,
              requires zero technical skills, and is completely free to use!
            </Typography>
          </Box>
          <Box
            className={`${classes.flexCenter}  sm:!justify-start justify-center`}
            sx={{
              gap: "2vw",
              p: { md: "1vw", xs: "5vw" },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={clases.buttonSx}
              onClick={() => UserService.doLogin()}
            >
              Try Now!
            </Button>
          </Box>
        </Grid>

        <Grid
          item
          xs={11}
          md={4.5}
          className={classes.flexCenter}
          sx={{ width: "100%", minHeight: "45vw" }}
        >
          <Grid item md={1} sx={{ display: { xs: "none", md: "flex" } }}></Grid>

          <Grid
            item
            xs={12}
            sx={{
              // display: { md: 'none', xs: 'flex' },
              display: "flex",
              height: {
                sm: "40vw",
                xs: "auto",
              },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <YoutubePlayer />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Entheogens;
