import { useState } from "react";
import { Grid, Typography, Button, Box, TextField } from "@mui/material";
// import { useLocation, useNavigate } from "react-router-dom";
import EPImg from "../../assets/images/egpt-logo-social.png";

import useStyles from "./styles/styles";

import FooterContact from "./components/FooterContact";
import SocialLink from "./components/SocialLink";
import { useDispatch } from "react-redux";
import { registerNewsletter } from "../../store/auth/actions";
import { toast } from "react-toastify";
import { errorHandler } from "../../utils/errorHandler";

const Footer = () => {
  const dispatch = useDispatch();
  const [newsletter, setNewsletter] = useState<string>("");

  const onChangeNewsletter = (e: any) => {
    setNewsletter(e.target.value);
  };

  const callBackFunc = (data: { status: string; returnData: any }) => {
    if (data.status === "success") {
      toast.success(data.returnData.data.message);
      setNewsletter("");
    } else {
      errorHandler(data.returnData);
    }
  };
  const onSubmit = () => {
    if (
      !String(newsletter)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return false;
    }
    dispatch(registerNewsletter(newsletter, callBackFunc));
  };
  const classes = useStyles();
  // const navigate = useNavigate();
  const clases = {
    //mobile responsive
    footerTitle: {
      fontSize: "7.63vw !important",
      fontWeight: "500 !important",
      color: "#FFFFFF !important",
      paragraph: "false !important",
      mt: "13.49vw",
      mb: "2vw",
    },
    footerContent: {
      fontSize: "5.09vw !important",
      fontWeight: "400 !important",
      color: "#FFFFFF !important",
      opacity: "0.7 !important",
      my: { md: "", xs: "3vw" },
      textDecoration: "none",
      // margin: '0 !important',
    },
    footerCopyright: {
      fontSize: { xs: "4.07vw", md: "1.04vw" },
      fontWeight: "400 !important",
      color: "#FFFFFF !important",
      margin: "0 !important",
      paragraph: "false",
      textDecoration: "none",
    },
    footerTOS: {
      fontSize: { xs: "3.56vw", md: "1.04vw" },
      fontWeight: "400 !important",
      color: "#FFFFFF !important",
      margin: "0 !important",
      paragraph: "false",
      textDecoration: "none",
    },
  };
  return (
    <Grid
      id="contact"
      container
      className={classes.sectionBox}
      sx={{ backgroundColor: "#0E1424", flexDirection: "column" }}
    >
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "row",
          justifyContent: "space-evenly",
          paddingTop: "2vw",
          px: "3vw",
          position: "relative",
          height: "fit-content",
          backgroundImage: "url(/images/home/footer_img1.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "20vw 8vw",
        }}
      >
        <Grid item xs={12} md={2.5}>
          <Box>
            <Typography className={classes.footerTitle}>
              Get In Touch
            </Typography>
          </Box>
          <Box>
            <FooterContact
              icon="footer_phone"
              name="Phone"
              contact="+1 415-390-6250"
            />
            <FooterContact
              icon="footer_mail"
              name="Email"
              contact="info@entheo.info"
            />
            <SocialLink />
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box>
            <Typography className={classes.footerTitle}>
              Useful Links
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Typography
              className={classes.footerContent}
              component="a"
              href="/#home"
            >
              Home
            </Typography>
            <Typography
              className={classes.footerContent}
              component="a"
              href="/#how"
            >
              How It Works
            </Typography>
            <Typography
              className={classes.footerContent}
              component="a"
              href="/#gptStore"
            >
              Browse the GPT Store
            </Typography>
            <Typography
              className={classes.footerContent}
              component="a"
              href="/#mission"
            >
              Mission and Vision
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box>
            <Typography className={classes.footerTitle}>Company </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Typography
              className={classes.footerContent}
              component="a"
              href="/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </Typography>
            <Typography
              className={classes.footerContent}
              component="a"
              href="/terms-of-service/"
              target="_blank"
            >
              Terms of Service
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box>
            <Typography className={classes.footerTitle}>Newsletter </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <TextField
              fullWidth
              color="secondary"
              // id="outlined-basic"
              variant="outlined"
              InputProps={{
                style: {
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "50vw",
                },
              }}
              value={newsletter}
              onChange={onChangeNewsletter}
              sx={{ marginBottom: "1vw" }}
            />
            <Button
              variant="contained"
              sx={{ width: "100%", marginBottom: "1vw" }}
              onClick={onSubmit}
            >
              Subscribe Now
            </Button>
            <Grid item xs={12} md={12} className={classes.flexCenter}>
              <Grid item xs={5.75} md={5.75}>
                <Box
                  component="img"
                  src="/images/home/footer_img2.png"
                  alt="logo"
                  className={classes.image}
                />
              </Grid>
              <Grid item xs={0.5} md={0.5}></Grid>
              <Grid item xs={5.75} md={5.75}>
                <Box
                  component="img"
                  src="/images/home/footer_img3.png"
                  alt="logo"
                  className={classes.image}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>

      <Grid
        item
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          justifyContent: "space-evenly",
          pb: "39.54vw",
          px: "8.33vw",
          position: "relative",
        }}
        xs={12}
      >
        <Grid item xs={12}>
          <Box>
            <Typography sx={clases.footerTitle}>Get In Touch</Typography>
          </Box>
          <Box>
            <FooterContact
              icon="footer_phone"
              name="Phone"
              contact="+1 415-390-6250"
            />
            <FooterContact
              icon="footer_mail"
              name="Email"
              contact="info@entheo.info"
            />
            <SocialLink />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography sx={clases.footerTitle}>Useful Links</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Typography sx={clases.footerContent} component="a" href="/#home">
              Home
            </Typography>
            {/* <Typography sx={clases.footerContent} component="a" href="/#about">
              About MushGPT
            </Typography> */}
            <Typography sx={clases.footerContent} component="a" href="/#how">
              How It Works
            </Typography>
            <Typography
              sx={clases.footerContent}
              component="a"
              href="/#gptStore"
            >
              Browse the GPT Store
            </Typography>
            <Typography
              sx={clases.footerContent}
              component="a"
              href="/#mission"
            >
              Mission and Vision
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography sx={clases.footerTitle}>Company </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Typography
              sx={clases.footerContent}
              component="a"
              href="/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </Typography>
            <Typography
              sx={clases.footerContent}
              component="a"
              href="/terms-of-service/"
              target="_blank"
            >
              Terms of Service
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography sx={clases.footerTitle}>Newsletter </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <TextField
              fullWidth
              color="secondary"
              id="outlined-basic"
              variant="outlined"
              InputProps={{
                style: {
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "50vw",
                  height: "50px",
                },
              }}
              value={newsletter}
              onChange={onChangeNewsletter}
              sx={{ marginBottom: "3vw" }}
            />
            <Button
              variant="contained"
              sx={{
                width: "100%",
                paddingY: "10px",
                marginBottom: "3vw",
                fontSize: { xs: "25px" },
              }}
              onClick={onSubmit}
            >
              Subscribe Now
            </Button>
            <Grid item xs={12} md={12} className={classes.flexCenter}>
              <Grid item xs={5.75} md={5.75}>
                <Box
                  component="img"
                  src="/images/home/footer_img2.png"
                  alt="logo"
                  className={classes.image}
                />
              </Grid>
              <Grid item xs={0.5} md={0.5}></Grid>
              <Grid item xs={5.75} md={5.75}>
                <Box
                  component="img"
                  src="/images/home/footer_img3.png"
                  alt="logo"
                  className={classes.image}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: { md: "flex", xs: "none" },
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "6vw",
          borderTop: "solid 1px #ffffff57",
        }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={EPImg}
            alt="logo"
            sx={{
              width: "5vw",
              p: ".8vw",
            }}
          />
          <Typography sx={clases.footerCopyright}>
            The Entheology Project © 2023 all rights reserved
          </Typography>
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Typography
            sx={clases.footerCopyright}
            component="a"
            href="https://docs.google.com/document/d/16MS26YPcDfgu-SAf0NBnd8Lwa1q5SjZ1wrIb6peBdm0/edit#heading=h.ynrhzi5tcsky"
          >
            Terms of Services
          </Typography>
          <Typography sx={clases.footerCopyright}>|</Typography>
          <Typography
            sx={clases.footerCopyright}
            component="a"
            href="https://docs.google.com/document/d/16MS26YPcDfgu-SAf0NBnd8Lwa1q5SjZ1wrIb6peBdm0/edit#heading=h.8k0yzu1suhwo"
            target="_blank"
          >
            Privacy Policy
          </Typography>
        </Grid> */}
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-center",
          height: "53.18vw",
          borderTop: "solid 1px #ffffff57",
        }}
      >
        <Grid>
          <Box
            component="img"
            src={EPImg}
            alt="logo"
            sx={{
              width: "15.27vw",
              mt: "5vw",
            }}
          />
        </Grid>
        <Grid>
          <Typography
            sx={{
              ...clases.footerCopyright,
              textAlign: "center",
              padding: "0 20px",
            }}
          >
            The Entheology Project © 2023 all rights reserved
          </Typography>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            flexBasis: "0",
          }}
        >
          <Typography
            sx={clases.footerTOS}
            component="a"
            href="https://docs.google.com/document/d/16MS26YPcDfgu-SAf0NBnd8Lwa1q5SjZ1wrIb6peBdm0/edit#heading=h.ynrhzi5tcsky"
          >
            Terms of Services
          </Typography>
          <Typography sx={clases.footerTOS}>|</Typography>
          <Typography
            sx={clases.footerTOS}
            component="a"
            href="https://docs.google.com/document/d/16MS26YPcDfgu-SAf0NBnd8Lwa1q5SjZ1wrIb6peBdm0/edit#heading=h.8k0yzu1suhwo"
            target="_blank"
          >
            Privacy Policy
          </Typography>
        </Grid> */}
      </Box>
    </Grid>
  );
};

export default Footer;
