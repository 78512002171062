import { Grid, Typography, Box } from "@mui/material";

import useStyles from "./styles/styles";

import Shape from "./components/Shape";

const ExploreGpts = () => {
  // const navigate = useNavigate();
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: { md: "3.63vw", xs: "0" },
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    header4: {
      fontSize: { xs: "7.63vw", md: "2.08vw !important" },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
      color: "#101010 !important",
      paddingY: { md: "0", xs: "0" },
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      my: { xs: "5vw", md: "1vw" },
      // textAlign: { xs: 'center', md: 'left' },
    },
    buttonSx: {
      width: { md: "18vw", xs: "78.63vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
      my: { xs: "9vw", md: "0" },
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };

  return (
    <>
      <Grid
        className={classes.sectionBox}
        id="gptStore"
        sx={{ background: "#ffffff" }}
      >
        <Shape secNumber="6" />

        <Grid
          container
          className={classes.containerGrid}
          sx={{
            paddingBottom: "2vw",
            pt: { xs: "17.3vw", md: "3.07vw" },
          }}
        >
          <Grid item className={classes.flexCenter} xs={11} md={12}>
            <Typography sx={clases.header2}>GPT Store Coming Soon!</Typography>
          </Grid>
          <Box
            className={classes.flexCenter}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Grid
              item
              xs={11}
              md={3.5}
              className={classes.flexCenter}
              sx={{ my: { xs: "15.26vw", md: "0" } }}
            >
              <Box
                component="img"
                src="/images/home/sec6_img.png"
                alt="logo"
                className={classes.image}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item xs={11} md={5.2}>
              <Box>
                <Typography sx={clases.contentText}>
                  We host “assistant coach” GPT agents that represent the
                  coach's persona which includes their speciality, experience
                  and coaching style. The assistants can securely help Seekers
                  with appointment scheduling, screenings and intake forms free
                  of charge!
                </Typography>
                <ul>
                  <li>
                    <Typography sx={clases.contentText}>
                      1. Seekers can match with coaches that use this platform
                      based on domain expertise, experience, title, rating,
                      gender, sexual orientation and coaching style (modality).
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={clases.contentText}>
                      2. Access our community of Entheo coaches who have
                      contributed their personal research to facilitate personal
                      transformations.
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ExploreGpts;
