import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// import footer from "./home/footer";
// import section1 from "./home/section1";

import Header from "./home/Header";
import Footer from "./home/Footer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../KeyCloackServices/UserService";
import { Container, Divider, Stack, Typography } from "@mui/material";
import { bgGradient } from "../utils/cssStyles";
import { styled, alpha } from "@mui/material/styles";

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
  }),
  position: "relative",
  overflow: "hidden",
  // [theme.breakpoints.up("md")]: {
  //   height: `calc(100vh - ${HEADER.H_MAIN_DESKTOP}px)`,
  // },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#000080",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Inter", "sans-serif"].join(","),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: ".7vw",
          width: "10vw",
          fontSize: "1vw",
          fontWeight: "700",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "none",
          borderRadius: "50vw",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "50vw",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        paragraph: true,
        mb: "1.5vw",
      },
    },
  },
});

const TermsOfServices = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (UserService.isLoggedIn()) {
      navigate("/chat");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <StyledRoot>
          <div className="bg-[#f7f7f7] py-8 flex justify-center items-center">
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0",
              }}
            >
              Terms of Service
            </Typography>
          </div>{" "}
          <Container className="bg-white">
            <Stack spacing={3} sx={{ p: 5, borderRadius: 2 }}>
              <Stack spacing={2}>
                <Typography variant="subtitle1">
                  <strong>Last Updated:</strong> 9/4/2024
                </Typography>
                <Typography variant="body1">
                  Welcome to <strong>EntheoGPT.com</strong>, a product of
                  <strong> Entheology Project Inc.</strong>. Please read these
                  Terms of Service (“Terms”) carefully before using our
                  services. By accessing or using the services provided by
                  <strong> EntheoGPT</strong>, you agree to be bound by these
                  Terms. If you do not agree with these Terms, please do not use
                  the platform.
                </Typography>
              </Stack>

              <Divider sx={{ borderStyle: "dashed" }} />

              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  1. Introduction
                </Typography>

                <Typography variant="body1">
                  EntheoGPT is an AI-driven chatbot platform that provides
                  coaching, support, and other services to users (“you,”
                  “your”). The service is operated by{" "}
                  <strong>Entheology Project Inc.</strong>
                  (“we,” “our,” “us”). The platform enables users to interact
                  with AI-powered agents for assistance, coaching, and advice.
                  <strong> EntheoGPT</strong> is designed to offer helpful
                  insights and responses, but it is not a substitute for
                  professional advice.
                </Typography>
                <Typography variant="body1">
                  By using the platform, you agree to these Terms, which outline
                  the rules and guidelines governing your use of the platform.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  2. Use of Services
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  2.1 Eligibility
                </Typography>
                <Typography variant={"body1"}>
                  To use <strong>EntheoGPT</strong>, you must:
                </Typography>
                <ul className="list-disc ps-5">
                  {[
                    "Be at least 18 years old.",
                    "Have the legal capacity to enter into this agreement.",
                    "Agree not to use the platform for illegal, harmful, or unauthorized purposes.",
                  ].map((itm: any) => (
                    <li>{itm}</li>
                  ))}
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  2.2 User Responsibilities
                </Typography>
                <Typography variant={"body1"}>
                  When using <strong> EntheoGPT</strong>, you agree to:
                </Typography>
                <ul className="list-disc ps-5">
                  {[
                    "Provide accurate and up-to-date information as requested.",
                    "Not misuse the platform by posting harmful, abusive, or illegal content.",
                    "Use the platform for its intended purposes, including seeking coaching or guidance from the chatbot.",
                  ].map((itm: any) => (
                    <li>{itm}</li>
                  ))}
                </ul>
                <Typography variant={"body1"}>
                  You understand that <strong> EntheoGPT</strong> does not
                  provide medical, financial, legal, or other professional
                  advice. Responses generated by the AI are for informational
                  purposes only and should not be considered a replacement for
                  consultation with a qualified professional.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  3. Account Creation and Security
                </Typography>

                <Typography variant="body1">
                  To access certain features of <strong>EntheoGPT</strong>, you
                  may need to create an account on our{" "}
                  <strong>KeyCloak server at iam.entheo.info</strong>, where we
                  securely store your personal information, including:{" "}
                </Typography>

                {["Name", "Email address", "Phone number"].map((itm: any) => (
                  <li>{itm}</li>
                ))}
                <Typography variant="body1">
                  You are responsible for safeguarding your account credentials
                  and any activity occurring under your account. You agree to
                  notify us immediately of any unauthorized use or breach of
                  your account security.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  4. Privacy Policy
                </Typography>

                <Typography variant="body1">
                  Your use of <strong> EntheoGPT</strong> is governed by our{" "}
                  <a
                    href="https://lims.entheo.info/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  , which details how we collect, use, and protect your personal
                  information. By using the platform, you acknowledge that you
                  have read and understand the Privacy Policy and agree to its
                  terms.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  5. Data Handling
                </Typography>

                <Typography variant="body1">
                  takes the security and privacy of your data seriously. Our
                  data handling practices are designed to comply with relevant
                  data protection laws, including HIPAA (where applicable).
                  Here’s how we manage your data:
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  5.1 Data Collection
                </Typography>
                <Typography variant="body1">
                  We collect and store the following types of data when you use
                  <strong> EntheoGPT</strong>:
                </Typography>
                <ul className="list-disc ps-5">
                  <li>
                    <strong>Personal Information:</strong> Collected during
                    account registration and stored securely on our{" "}
                    <strong>KeyCloak server at iam.entheo.info</strong>. This
                    includes your Name, Email address, and Phone number.
                  </li>
                  <li>
                    <strong>Interaction Data:</strong> This includes user
                    prompts, AI-generated responses, and interaction metadata
                    (e.g., session duration, timestamps).
                  </li>
                  <li>
                    <strong>Metadata:</strong> We collect non-personal data such
                    as user intent, sentiment, and the context of interactions
                    for improving the performance of <strong>EntheoGPT</strong>.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  5.2 Anonymization and PII/PHI Handling
                </Typography>
                <ul className="list-disc ps-5">
                  <li>
                    <strong>Anonymization:</strong> Before storing or processing
                    interaction data, we strip out any Personally Identifiable
                    Information (PII) and Protected Health Information (PHI).
                    This ensures that sensitive information is not retained or
                    used inappropriately.
                  </li>
                  <li>
                    <strong>PHI and HIPAA Compliance:</strong> If your
                    interactions involve health-related topics, we anonymize and
                    securely handle any PHI in compliance with HIPAA
                    regulations.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  5.3 Data Usage
                </Typography>
                <Typography variant="body1">
                  We use the data collected to:
                </Typography>
                <ul className="list-disc ps-5">
                  {[
                    "Improve the AI’s responses and user experience.",
                    "Conduct analytics and performance monitoring on the platform.",
                    "Provide insights to coaches and system administrators, without revealing any personal data.",
                  ].map((itm: any) => (
                    <li>{itm}</li>
                  ))}
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  5.4 Data Sharing
                </Typography>

                <ul className="list-disc ps-5">
                  <li>
                    <strong>With Accredited Laboratories or Coaches:</strong>{" "}
                    Weonly share anonymized and paraphrased interaction data,
                    and no personal data is shared unless legally required or
                    authorized by you.
                  </li>
                  <li>
                    <strong>With Service Providers:</strong> We may share data
                    with trusted third-party vendors for payment processing,
                    cloud storage, and technical support, all of whom are bound
                    by confidentiality agreements.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  5.4 Data Sharing
                </Typography>
                <Typography variant={"body1"}>
                  We employ industry-standard security measures to protect your
                  data, including:
                </Typography>
                <ul className="list-disc ps-5">
                  <li>
                    <strong>Encryption in Transit and at Rest:</strong> All
                    personal and interaction data is encrypted to prevent
                    unauthorized access. This includes any data transmitted
                    between your device and our servers using{" "}
                    <strong>TLS encryption</strong>.
                  </li>
                  <li>
                    <strong>Access Controls:</strong> Access to sensitive data
                    is restricted to authorized personnel only, with role-based
                    permissions in place to ensure that only necessary personnel
                    can access specific data.
                  </li>
                  <li>
                    <strong>Audit Trails and Monitoring:</strong> We regularly
                    monitor and audit our systems to detect and prevent
                    unauthorized access, security breaches, or data misuse.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  5.6 Data Retention
                </Typography>
                <Typography variant={"body1"}>
                  We retain personal and interaction data only as long as
                  necessary to fulfill the purposes outlined in this policy or
                  as required by law. Anonymized interaction data may be stored
                  for longer durations for model fine-tuning and performance
                  improvement.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  6. Restrictions on Use
                </Typography>

                <Typography variant="body1">You agree not to:</Typography>

                <ul className="list-disc ps-5">
                  <li>Reverse-engineer or misuse the AI system.</li>
                  <li>
                    Share, distribute, or resell any part of the
                    <strong> EntheoGPT</strong> service without our permission.
                  </li>
                  <li>
                    Submit any harmful, illegal, defamatory, or abusive content
                    through the platform.
                  </li>
                  <li>
                    Use the platform in any manner that could harm the system or
                    disrupt other users’ experiences.
                  </li>
                </ul>
                <Typography variant="body1">
                  Violation of these restrictions may result in the termination
                  of your access to <strong> EntheoGPT</strong> without prior
                  notice.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  7. Limitation of Liability
                </Typography>

                <Typography variant="body1">
                  To the fullest extent permitted by law:
                </Typography>

                <ul className="list-disc ps-5">
                  <li>
                    <strong>Entheology Project Inc. </strong> will not be liable
                    for any indirect, incidental, special, or consequential
                    damages arising out of your use of{" "}
                    <strong> EntheoGPT</strong>, including, but not limited to,
                    damages for lost profits, data loss, or service
                    interruptions.
                  </li>
                  <li>
                    The total liability of{" "}
                    <strong> Entheology Project Inc.</strong> for any claim
                    relating to the use of <strong> EntheoGPT</strong> is
                    limited to the amount you paid for the services, if
                    applicable, in the 12 months preceding the claim.
                  </li>
                </ul>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  8. Dispute Resolution
                </Typography>

                <Typography variant="body1">
                  Any disputes arising from or related to these Terms or your
                  use of <strong> EntheoGPT</strong> will be governed by the
                  laws of California. You agree that any legal action or
                  proceeding will be resolved in the state or federal courts
                  located in <strong>San Francisco, California</strong>.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  9. Changes to Terms of Service
                </Typography>

                <Typography variant="body1">
                  We reserve the right to modify or update these Terms at any
                  time. If changes are made, we will notify you by updating the
                  "Last Updated" date at the top of this page. Your continued
                  use of the platform after changes are posted constitutes your
                  acceptance of the revised Terms.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  10. Termination
                </Typography>

                <Typography variant="body1">
                  We may suspend or terminate your access to{" "}
                  <strong>EntheoGPT</strong> at any time for violation of these
                  Terms or if required by law. Upon termination, you will no
                  longer have access to the platform or the data associated with
                  your account.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  11. Contact Us
                </Typography>

                <Typography variant="body1">
                  If you have any questions or concerns regarding these Terms or
                  your use of <strong> EntheoGPT</strong>, please contact us at:
                </Typography>
                <Typography variant="body1">
                  <strong> Entheology Project Inc.</strong> <br />
                  2021 Fillmore St PMB 2035
                  <br />
                  San Francisco, CA 94115 <br />
                  Phone: 415-390-6250 <br />
                  Email:{" "}
                  <a href="mailto:admin@entheo.info">admin@entheo.info</a>{" "}
                  <br />
                  <a
                    href="/terms-of-service/"
                    className="text-[#000080] font-bold"
                  >
                    https://entheogpt.com/terms-of-service/
                  </a>
                  <br />
                  EIN: <strong>88-1953133</strong>
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </StyledRoot>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default TermsOfServices;
