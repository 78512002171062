import { useEffect, useState } from "react";
import { BsPinAngle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { BiHide } from "react-icons/bi";
import { httpMushPro } from "../../../services/http-common";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { addFavGpts, getAllFavGpts } from "../../../store/bot/actions";

const AddGptSidebar = () => {
  const { botDetails } = useSelector((state) => state.bot);
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState(null);
  const { favGpts } = useSelector((state) => state.bot);

  const unFavGPT = useMutation(
    (ids) => {
      return httpMushPro.put(`user/bots/favourite`, { bots: ids }, null);
    },
    {
      onSuccess: ({ data }) => {
        dispatch(getAllFavGpts());
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const addGPT = (id) => {
    dispatch(addFavGpts(botDetails?.id));
  };
  const hideGPT = () => {
    let favIds = selectedIds.filter((id) => id !== botDetails?.id);
    unFavGPT.mutate(favIds);
  };

  useEffect(() => {
    let favIds = favGpts[0]?.item?.map((item) => item.id);
    setSelectedIds(favIds);
  }, [favGpts]);

  return (
    <>
      <span
        className={`flex justify-start items-center gap-2 cursor-pointer`}
        onClick={() =>
          selectedIds && selectedIds.includes(botDetails?.id)
            ? hideGPT()
            : addGPT()
        }
      >
        {selectedIds && selectedIds.includes(botDetails?.id) ? (
          <>
            <BiHide className="text-base" /> Hide from sidebar
          </>
        ) : (
          <>
            <BsPinAngle className="text-base" /> Keep in sidebar
          </>
        )}
      </span>
    </>
  );
};

export default AddGptSidebar;
