import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";
import { MainControllerProvider } from "./context/index";
import UserService from "./KeyCloackServices/UserService";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
// <>
//   <ThemeProvider>
//     <MainControllerProvider>
//       <App />
//     </MainControllerProvider>
//   </ThemeProvider>
// </>
// );

const renderApp = () =>
  createRoot(document.getElementById("root") as HTMLElement).render(
    <>
      <ThemeProvider>
        <MainControllerProvider>
          <App />
        </MainControllerProvider>
      </ThemeProvider>
    </>
  );

// serviceWorker.unregister()
UserService.initKeycloak(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
