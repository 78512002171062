import { call, all, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../constants";
import { toast } from "react-toastify";

import apiClient from "../apiClient";

import { getAccessToken } from "../../utils/jwt";

import { ServerResponse } from "node:http";
import { AxiosResponse } from "axios";
import apiMushPro from "../apiMushPro";

async function shooterUpdateMycBot(userInfo: any) {
  return apiClient.put("/user/magic-myc", userInfo, {
    headers: { Authorization: getAccessToken() },
  });
}

function* updateBotMyc(): any {
  return yield takeLatest(
    actionTypes.UPDATE_BOT_MYC_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterUpdateMycBot,
          action.payload
        );
        yield put({
          type: actionTypes.GET_BOT_DATA_MYC_REQUEST,
          payload: {
            ...data,
          },
        });
        toast.success("Your Bot updated Successfully.");
      } catch (error: any) {
        action.helpers.setStatus({ success: false });
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data.message
            : error.message
        );
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        });
        action.helpers.setSubmitting(false);
      }
    }
  );
}

async function shooterhandleUploadFiles(file: any) {
  return apiClient.post("/user/image-uploader", file, {
    headers: {
      Authorization: getAccessToken(),
      "Content-type": "multipart/form-data",
    },
  });
}

function* handleUploadImages(): any {
  return yield takeLatest(
    actionTypes.UPLOAD_IMAGE_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterhandleUploadFiles,
          action.payload
        );
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}

async function shooterGetBotMycData(): Promise<AxiosResponse<any, any>> {
  return apiClient.get(`/user/magic-myc`, {
    headers: { Authorization: getAccessToken() },
  });
}

function* getBotDataMyc(): any {
  return yield takeLatest(
    actionTypes.GET_BOT_DATA_MYC_REQUEST,
    function* (action: any) {
      try {
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterGetBotMycData
        );
        yield put({
          type: actionTypes.GET_BOT_DATA_MYC_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  );
}
async function shooterhandleUpgradeToPro() {
  return apiClient.post(
    "user/apply-for-pro",
    {},
    {
      headers: {
        Authorization: getAccessToken(),
      },
    }
  );
}

function* handleUpgradeToPro(): any {
  return yield takeLatest(
    actionTypes.UPGRADE_TO_PRO_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(shooterhandleUpgradeToPro);
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}

// ---------------------------------------------------

async function shooterGetGpts() {
  return apiMushPro.post(
    "user/bots/fetchBots",
    {},
    {
      headers: {
        Authorization: getAccessToken(),
      },
    }
  );
}

function* getGpts(): any {
  return yield takeLatest(
    actionTypes.GET_ALL_GPTS_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(shooterGetGpts);
        yield put({
          type: actionTypes.GET_ALL_GPTS_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data.message
            : error.message
        );
      }
    }
  );
}

async function shooterGetFavGpts() {
  return apiMushPro.get(`/user/bots/favourite`, {
    headers: { Authorization: getAccessToken() },
  });
}

function* getFavGpts(): any {
  return yield takeLatest(
    actionTypes.GET_ALL_FAV_GPTS_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(shooterGetFavGpts);
        yield put({
          type: actionTypes.GET_ALL_FAV_GPTS_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}

async function shooterAddFavGpt(
  botId: string
): Promise<AxiosResponse<any, any>> {
  return apiMushPro.post(
    `/user/bots/favourite`,
    { bots: [botId] },
    {
      headers: { Authorization: getAccessToken() },
    }
  );
}

function* addFavGpt(): any {
  return yield takeLatest(
    actionTypes.ADD_FAV_GPTS_REQUEST,
    function* (action: any) {
      try {
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterAddFavGpt,
          action.payload
        );
        yield put({
          type: actionTypes.GET_ALL_FAV_GPTS_REQUEST,
        });
      } catch (error) {
        console.log(error);
      }
    }
  );
}
async function shooterGetBotDetails(
  botUniqueName: string
): Promise<AxiosResponse<any, any>> {
  return apiMushPro.post(
    `/user/getBotData/${botUniqueName}`,
    {},
    {
      headers: { Authorization: getAccessToken() },
    }
  );
}

function* getBotDetails(): any {
  return yield takeLatest(
    actionTypes.GET_BOT_DATA_REQUEST,
    function* (action: any) {
      try {
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterGetBotDetails,
          action.payload
        );
        yield put({
          type: actionTypes.GET_BOT_DATA_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  );
}

async function shooterGetBotChatHistory(
  chatId: string
): Promise<AxiosResponse<any, any>> {
  return apiMushPro.post(
    `/user/bots/getAllMessages`,
    { botId: chatId },
    {
      headers: { Authorization: getAccessToken() },
    }
  );
}

function* getBotChatHistory(): any {
  return yield takeLatest(
    actionTypes.GET_BOT_CHAT_HISTORY_REQUEST,
    function* (action: any) {
      try {
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterGetBotChatHistory,
          action.payload
        );
        yield put({
          type: actionTypes.GET_BOT_CHAT_HISTORY_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  );
}

async function shooterGetBotAnswer(
  data: object
): Promise<AxiosResponse<any, any>> {
  return apiMushPro.post("/user/bots/writeMessage", data, {
    headers: { Authorization: getAccessToken() },
  });
}

function* getBotAnswer(): any {
  return yield takeLatest(
    actionTypes.GET_BOT_ANSWER_REQUEST,
    function* (action: any) {
      try {
        yield put({
          type: actionTypes.ADD_BOT_MESSAGE,
          payload: action.payload,
        });
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterGetBotAnswer,
          action.payload
        );
        yield put({
          type: actionTypes.ADD_CHAT_BOT_MESSAGE,
          payload: {
            ...data,
          },
        });
      } catch (error) {
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_ERROR,
        });
        console.log(error);
      }
    }
  );
}

async function shooterResetBotChatHistory(
  id: string
): Promise<AxiosResponse<any, any>> {
  return apiMushPro.delete(`/user/bots/resetMessages`, {
    data: {
      botId: id,
    },
    headers: { Authorization: getAccessToken() },
  });
}

function* resetBotChatHistory(): any {
  return yield takeLatest(
    actionTypes.DELETE_BOT_CHAT_HISTORY_REQUEST,
    function* (action: any) {
      try {
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterResetBotChatHistory,
          action.payload
        );
        toast.success("Reset Successfully.");
        yield put({
          type: actionTypes.GET_ALL_FAV_GPTS_REQUEST,
        });
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export default function* runBlockingCallsExample() {
  yield all([
    updateBotMyc(),
    handleUploadImages(),
    getBotDataMyc(),
    handleUpgradeToPro(),
    getGpts(),
    getFavGpts(),
    addFavGpt(),
    getBotChatHistory(),
    resetBotChatHistory(),
    getBotAnswer(),
    getBotDetails(),
  ]);
}
