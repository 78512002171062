import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";

import useStyles from "./styles/styles";
import Shape from "./components/Shape";
import UserService from "../../KeyCloackServices/UserService";

const JoinWaitlist = ({ heading, text }: any) => {
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7.63vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: "3vw",
      py: "1vw",
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    header4: {
      fontSize: { xs: "5.09vw !important", md: "2.08vw !important" },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
      color: "#101010 !important",
      textAlign: "center",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      textAlign: "center",
      // textAlign: { xs: 'center', md: 'left' },
      py: "1vw",
    },
    buttonSx: {
      width: { md: "13.22vw", xs: "66.16vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
      my: { xs: "3vw", md: "0" },
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };
  return (
    <Grid className={classes.sectionBox}>
      <Shape secNumber="4" />
      <Grid
        item
        container
        xs={12}
        md={12}
        className={classes.flexCenter}
        sx={{
          flexDirection: "column",
          py: { xs: "15.77vw", md: "3vw" },
        }}
      >
        <Typography sx={clases.header3} className="!text-center">
          {heading}
        </Typography>
        <Grid
          item
          xs={11}
          md={8.7}
          className={classes.flexCenter}
          sx={{
            flexDirection: "column",
          }}
        >
          <Grid item xs={11} md={10}>
            <Typography sx={clases.contentText}>{text}</Typography>
          </Grid>
          <Box
            className={classes.flexCenter}
            sx={{
              gap: "2vw",
              p: { md: "1vw", xs: "5vw" },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={clases.buttonSx}
              onClick={() => UserService.doLogin()}
            >
              Try Now!
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JoinWaitlist;
