import PropTypes from "prop-types";
import { Grid, Box, IconButton } from "@mui/material";

import SideBar from "../components/sidebar";
import Header from "../components/header";
// import { Helmet } from "react-helmet";

// import metaDescriptor from "../utils/metaDestriptor.json";
// import type
import { FC, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showSideBarWeb } from "../store/dataFlow/actions";
interface LayoutsProps {
  children?: ReactNode;
}

const Layouts: FC<LayoutsProps> = ({ children }) => {
  const { sidebarWeb } = useSelector((state: any) => state.dataFlow);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    sidebarWeb
      ? dispatch(showSideBarWeb(false))
      : dispatch(showSideBarWeb(true));
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        maxWidth: "1920px",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {sidebarWeb ? (
        <Grid
          item
          lg={4}
          xl={3}
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            padding: "10px 40px 26px 40px",
          }}
        >
          <SideBar />
        </Grid>
      ) : (
        <Grid
          item
          lg={2}
          xl={2}
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            padding: "10px 40px 26px 40px",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mx: 0, mr: 2, mb: 0, display: { xs: "none", lg: "flex" } }}
            onClick={() => {
              handleSidebar();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              className="icon-lg juice:mx-2.5 juice:text-token-text-secondary"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M3 8a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1m0 8a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1"
                clip-rule="evenodd"
              ></path>
            </svg>
          </IconButton>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        lg={!sidebarWeb ? 9 : 8}
        xl={!sidebarWeb ? 10 : 9}
        sx={{
          paddingLeft: { xs: "20px", md: "40px", lg: "0px" },
          paddingRight: { xs: "20px", md: "40px" },
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            width: "100%",
            zIndex: 999,
          }}
        >
          <Header />
        </Box>
        {children}
      </Grid>
    </Grid>
  );
};

Layouts.propTypes = {
  children: PropTypes.node,
};

export default Layouts;
