import { call, all, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../constants";
import { toast } from "react-toastify";

import apiClient from "../apiClient";

import { getAccessToken } from "../../utils/jwt";

import { ServerResponse } from "node:http";
import UserService from "../../KeyCloackServices/UserService";
import apiMushPro from "../apiMushPro";

// async function getJwtToken(data: any) {
// 	if(localStorage.getItem("accessToken")){
// 		// console.log(localStorage.getItem("accessToken"))
// 		// if(data?.data?.user?.role === 'pro'){
// 			const myToken = await apiClient.post('/user/embedding-token', {}, {
// 				headers: {Authorization: `${localStorage.getItem("accessToken")}`},
// 			});
// 			myToken && localStorage.setItem('botAccessTokenEncoded', myToken.data.token)
// 		// }
// 	}
// }

async function shooterLoginByKc() {
  return apiMushPro.post(
    "/user/get-users",
    {
      query: UserService.getUserId(),
    },
    {
      headers: { Authorization: getAccessToken() },
    }
  );
}

function* loginByKc(): any {
  return yield takeLatest(actionTypes.LOGIN_BY_KC, function* (action: any) {
    try {
      const data: ServerResponse = yield call(shooterLoginByKc);
      yield put({
        type: actionTypes.LOGIN_BY_KC_SUCCESS,
        payload: {
          ...data,
        },
      });
    } catch (error) {
      console.log(error);
    }
  });
}

async function shooterSignIn(userInfo: any) {
  return apiClient.post("/login", userInfo);
}

function* signIn(): any {
  return yield takeLatest(
    actionTypes.POST_SIGNIN_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(shooterSignIn, action.payload);
        action.setLoading(false);
        yield put({
          type: actionTypes.POST_SIGNIN_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
        // getJwtToken(data)
        action.navigate("/chat");
      } catch (error: any) {
        action.setLoading(false);

        action.helpers.setStatus({ success: false });
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        });
        action.helpers.setSubmitting(false);
      }
    }
  );
}

async function shooterForgetPassword(userInfo: any) {
  return apiClient.post("/user/forget-password", userInfo);
}
function* forgetPassword(): any {
  return yield takeLatest(
    actionTypes.POST_FORGET_PASSWORD_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterForgetPassword,
          action.payload
        );
        yield put({
          type: actionTypes.POST_FORGET_PASSWORD_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
        action.navigate("/reset-password");
        toast.success("Otp sent successfully, use otp to reset password");
      } catch (error: any) {
        console.log(error);
        action.helpers.setStatus({ success: false });
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data.message
            : error.message
        );
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        });
        action.helpers.setSubmitting(false);
      }
    }
  );
}

async function shooterResetPassword(userInfo: any) {
  return apiClient.post("user/reset-password", userInfo);
}

function* resetPassword(): any {
  return yield takeLatest(
    actionTypes.POST_RESET_PASSWORD_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterResetPassword,
          action.payload
        );
        yield put({
          type: actionTypes.POST_RESET_PASSWORD_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
        toast.success("Your password reset successfully !");
        action.navigate("/");
      } catch (error: any) {
        console.log(error);
        action.helpers.setStatus({ success: false });
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data.message
            : error.message
        );
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        });
        action.helpers.setSubmitting(false);
      }
    }
  );
}

async function shooterSignInGoogle(data: any) {
  return apiClient.post("/login/google", {
    email: data.email,
  });
}

async function shooterRegisterGoogleUser(userInfo: any) {
  return apiClient.post("/user/create", {
    firstName: userInfo.name,
    email: userInfo.email,
    password: userInfo.given_name + userInfo.id,
    confirmPassword: userInfo.given_name + userInfo.id,
    phoneNumber: "",
    emailVerify: true,
  });
}

function* signInGoogle(): any {
  return yield takeLatest(
    actionTypes.POST_GOOGLE_SIGNIN_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterSignInGoogle,
          action.payload
        );
        yield put({
          type: actionTypes.POST_GOOGLE_SIGNIN_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
        action.navigate("/chat");
      } catch (error: any) {
        const data: ServerResponse = yield call(
          shooterRegisterGoogleUser,
          action.payload
        );
        yield put({
          type: actionTypes.POST_GOOGLE_SIGNIN_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
        action.navigate("/chat");
      }
    }
  );
}

async function shooterGetUserInfo(accessToken: any) {
  return apiClient.get("/user/verify", {
    headers: { Authorization: accessToken },
  });
}

function* getUserInfo(): any {
  return yield takeLatest(
    actionTypes.GET_USER_INFO_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterGetUserInfo,
          action.payload
        );
        yield put({
          type: actionTypes.GET_USER_INFO_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error) {
        localStorage.removeItem("accessToken");
        action.navigate && action.navigate("/signin");
      }
    }
  );
}

async function shooterRegisterUser(userInfo: any) {
  return apiClient.post("/user", {
    firstName: userInfo.firstName,
    phoneNumber: userInfo.phoneNumber,
    email: userInfo.email,
    password: userInfo.password,
    confirmPassword: userInfo.passwordConfirm,
  });
}

function* registerUser(): any {
  return yield takeLatest(
    actionTypes.REGISTER_USER_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterRegisterUser,
          action.payload
        );
        yield put({
          type: actionTypes.REGISTER_USER_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
        action.navigate("/chat");
      } catch (error: any) {
        console.log(error);
        action.helpers.setStatus({ success: false });
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        });
        action.helpers.setSubmitting(false);
      }
    }
  );
}

async function shooterDeleteProfile(userInfo: any) {
  return apiClient.delete(`/user/delete`, {
    headers: { Authorization: getAccessToken() },
    data: {
      id: userInfo,
    },
  });
}

function* deleteProfile(): any {
  return yield takeLatest(
    actionTypes.POST_Delete_Profile_REQUEST,
    function* (action: any) {
      try {
        yield call(shooterDeleteProfile, action.payload);
        yield put({
          type: actionTypes.POST_Delete_Profile_SUCCESS,
        });
      } catch (error: any) {
        console.log(error);
        action.helpers.setStatus({ success: false });
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        });
        action.helpers.setSubmitting(false);
      }
    }
  );
}
async function shooterUpdateProfile(userInfo: any) {
  return apiMushPro.put(
    `/user/update?code=${UserService.getUserId()}`,
    userInfo,
    {
      headers: { Authorization: getAccessToken() },
    }
  );
}

function* updateProfile(): any {
  return yield takeLatest(
    actionTypes.UPDATE_PROFILE_REQUEST,
    function* (action: any) {
      try {
        yield call(shooterUpdateProfile, action.payload);
        yield put({
          type: actionTypes.LOGIN_BY_KC,
        });
        action.setLoading(false);
        toast.success("Successfully updated!");
      } catch (error: any) {
        action.setLoading(false);
        toast.error(error?.response?.data?.message);

        action.helpers.setStatus({ success: false });
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        });
        action.helpers.setSubmitting(false);
      }
    }
  );
}

function shooterSendHelpEmail() {
  return apiClient.post(
    "/user/sendhelpemail",
    {},
    {
      headers: { Authorization: getAccessToken() },
    }
  );
}

function* sendHelpEmail(): any {
  return yield takeLatest(
    actionTypes.SEND_HELP_EMAIL_REQUEST,
    function* (action: any) {
      try {
        yield call(shooterSendHelpEmail);
        action.callBack("success");
      } catch (error) {
        action.callBack("error");
      }
    }
  );
}

function shooterRegisterNewsletter(email: string) {
  return apiClient.post(
    "/user/newsletter",
    { email },
    {
      headers: { Authorization: getAccessToken() },
    }
  );
}

function* registerNewsletter(): any {
  return yield takeLatest(
    actionTypes.REGISTER_NEWSLETER_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterRegisterNewsletter,
          action.payload
        );
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error,
        });
        return false;
      }
    }
  );
}

export default function* runBlockingCallsExample() {
  yield all([
    signIn(),
    loginByKc(),
    forgetPassword(),
    resetPassword(),
    signInGoogle(),
    getUserInfo(),
    registerUser(),
    updateProfile(),
    deleteProfile(),
    sendHelpEmail(),
    registerNewsletter(),
  ]);
}
