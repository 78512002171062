import axios from "axios";

const apiClient = axios.create({
  // baseURL: `https://api.entheogpt.com/`,
  // baseURL: `https://dev-api.entheogpt.com/`,
  // baseURL: `http://localhost:8000/`,
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export default apiClient;
