import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
} from "@mui/material";

// ** Import type
import { FC, ReactNode } from "react";

// ** Import action
import { logout } from "../../store/auth/actions";
import { cleanMessages } from "../../store/messages/actions";

// ** define type
interface Item {
  title: string;
  path?: string;
  iconUrl?: string;
  permission?: string;
  icon: ReactNode;
}

interface Section {
  key: number;
  title?: string;
  item: Item[];
}

interface SideBarSectionProps {
  item: Section;
}

export const BootstrapList = styled(List)(({ theme }) => ({
  "& .Mui-selected": {
    backgroundColor: "transparent !important",
    border: "4.01px solid #A52230",
    borderTopStyle: "none",
    borderRightStyle: "none",
    borderBottomStyle: "none",
    "& .MuiListItemIcon-root": {
      color:
        theme?.palette.mode === "light" ? "#A52230" : "rgba(255,255,255,1)",
    },
    span: {
      color:
        theme?.palette.mode === "light" ? "#A52230" : "rgba(255,255,255,1)",
    },
  },
  "& .MuiListItemIcon-root": {
    color:
      theme?.palette.mode === "light" ? "#000080" : "rgba(255,255,255,0.5)",
    minWidth: "50px",
  },
  "& .MuiSvgIcon-root": {
    width: "26.75px",
    height: "26.71px",
  },
  "& .MuiButtonBase-root": {
    paddingLeft: "34.68px",
    paddingTop: "3px",
    paddingBottom: "3px",
    marginTop: "0px",
  },
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
    "& .MuiListItemIcon-root": {
      color:
        theme?.palette.mode === "light" ? "#A52230" : "rgba(255,255,255,1)",
    },
    span: {
      color:
        theme?.palette.mode === "light" ? "#A52230" : "rgba(255,255,255,1)",
    },
  },
  "& .MuiListSubheader-root": {
    backgroundColor: "transparent",
    fontFamily: "DM Sans",
    fontWeight: "700",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "1.78",
    color: theme?.palette.mode === "light" ? "#000080" : "rgba(255,255,255,1)",
    marginTop: "16px",
  },
  span: {
    fontFamily: "DM Sans",
    fontWeight: "700",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: 1.78,
    color:
      theme?.palette.mode === "light" ? "#000080" : "rgba(255,255,255,0.7)",
  },
}));
const SidebarSection: FC<SideBarSectionProps> = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);

  const onClickHandler = (path: string | undefined) => {
    if (typeof path === "string") {
      return () => {
        navigate(path);
      };
    } else {
      return () => {
        // alert("here");
        dispatch(cleanMessages());
        dispatch(logout());
      };
    }
  };
  return (
    <>
      <nav aria-label="main mailbox folders">
        <BootstrapList
          subheader={
            item.title ? <ListSubheader>{item.title}</ListSubheader> : null
          }
        >
          {item?.item?.map((_item) => {
            // if (user.role === "user" && !_item.permission) {
            if (
              _item.permission !== "pro" ||
              user.role === _item.permission ||
              user.role === "admin"
            ) {
              return (
                <ListItem
                  key={_item.title}
                  disablePadding
                  sx={{
                    marginBottom: "13px",
                  }}
                >
                  <ListItemButton
                    selected={location.pathname === _item.path}
                    onClick={onClickHandler(_item.path)}
                  >
                    {_item.icon ? (
                      <ListItemIcon
                        sx={{
                          justifyContent:
                            _item.title !== "Magic Myc" ? "center" : "",
                        }}
                      >
                        {_item.icon}
                      </ListItemIcon>
                    ) : (
                      <ListItemIcon
                        sx={{
                          justifyContent:
                            _item.title !== "Magic Myc" ? "center" : "",
                        }}
                      >
                        <img
                          src={_item?.iconUrl}
                          alt="bot logo"
                          className="max-w-[40px] max-h-[40px]"
                        />
                      </ListItemIcon>
                    )}

                    <ListItemText primary={_item.title} />
                  </ListItemButton>
                </ListItem>
              );
            } else {
              return null;
            }
          })}
        </BootstrapList>
      </nav>
    </>
  );
};

export default SidebarSection;
