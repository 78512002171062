import React from 'react';
import { Grid, Box } from '@mui/material';
import useStyles from '../styles/styles';

import { YouTube, LinkedIn } from '@mui/icons-material';

const SocialLink = () => {
	const classes = useStyles();

	return (
		<Grid
			item
			xs={11}
			md={12}
			className={classes.flexCenter}
			sx={{ paddingTop: { xs: '9vw', md: '2vw' } }}
		>
			<Grid item xs={2} md={2} className={classes.flexCenter}>
				<a
					href="http://facebook.com/theentheoproject"
					target="_blank"
					rel="noreferrer"
				>
					{/* <FacebookIcon sx={{ color: "#550524"}} /> */}
					<Box
						component="img"
						src={'/images/home/ic-facebook.png'}
						alt="logo"
						sx={{
							height: '100%',
							width: { md: '1.2vw', xs: '3vw' },
							alignItems: 'center',
						}}
					/>
				</a>
			</Grid>
			<Grid item xs={2} md={2} className={classes.flexCenter}>
				<a
					href="http://linkedin.com/company/theentheoproject"
					target="_blank"
					rel="noreferrer"
				>
					{/* <TwitterIcon color="secondary" fontSize="large" sx={{ width: "100%" }} /> */}
					{/* <Box
					component="img"
					src={'/images/home/ic-twitter.png'}
					alt="logo"
					sx={{ height: '100%' }}
				/> */}
					<LinkedIn
						sx={{
							color: 'white',
							width: '100%',
							fontSize: { md: '2.5vw', xs: '7vw' },
						}}
					/>
				</a>
			</Grid>
			<Grid item xs={2} md={2} className={classes.flexCenter}>
				{/* <InstagramIcon /> */}
				<a
					href="https://instagram.com/theentheoproject"
					target="_blank"
					rel="noreferrer"
				>
					<Box
						component="img"
						src={'/images/home/ic-instagram.png'}
						alt="logo"
						sx={{ height: '100%', width: { md: '2vw', xs: '5.5vw' } }}
					/>
				</a>
			</Grid>
			<Grid item xs={2} md={2} className={classes.flexCenter}>
				{/* <PinterestIcon /> */}
				<a
					href="https://www.youtube.com/@theentheoproject"
					target="_blank"
					rel="noreferrer"
				>
					{/* <Box
						component="img"
						src={'/images/home/ic-pinterset.png'}
						alt="logo"
						sx={{ height: '100%' }}
					/> */}
					<YouTube
						sx={{
							color: 'white',
							width: '100%',
							fontSize: { md: '2.5vw', xs: '8vw' },
						}}
					/>
				</a>
			</Grid>
			<Grid item xs={4} md={4} className={classes.flexCenter}></Grid>
		</Grid>
	);
};

export default SocialLink;
