import { useRef, useEffect, useState, useCallback } from "react";
import ContentEditable from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";

// ** Import MUI
import { IconButton, Box, useTheme } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import StopCircleIcon from "@mui/icons-material/StopCircle";
// ** Import Type
import { FC } from "react";

// ** Import action
import { getAnswer, updateChatStreaming } from "../../store/messages/actions";
interface ContentEditProps {
  setShowSuggestions: (showSuggestions: boolean) => void;
  handleStopStreaming: () => void;
}

const ContentEdit: FC<ContentEditProps> = ({
  setShowSuggestions,
  handleStopStreaming,
}) => {
  const { isStreaming } = useSelector((state: any) => state.msg);
  const dispatch = useDispatch();
  const { model } = useSelector((state: any) => state.dataFlow);
  const theme = useTheme();
  const [text, setText] = useState("");
  const inputBoxRef = useRef(null);

  const handleChange = (evt: any) => {
    // const value = evt.target.value.replace(/<[^>]+>/g, "");
    const value = evt.target.value.replace(/<\/?[^>]+(>|$)/g, "");
    setText(value);
  };

  const onSubmit = useCallback(() => {
    if (text !== "") {
      dispatch(
        getAnswer({
          finalChat: text,
          llm: model?.name?.toLocaleLowerCase(),
          type: model?.type,
        })
      );
      dispatch(updateChatStreaming(true));
      setShowSuggestions(false);
      setText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, dispatch]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand("insertLineBreak");
        } else {
          onSubmit();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* <AlertDeleteChat setShowSuggestions={setShowSuggestions} /> */}
        <ContentEditable
          disabled={isStreaming}
          className={`content-editable !p-[12px_24px] !rounded-md ${
            isStreaming && "cursor-wait"
          } ${theme?.palette.mode === "dark" && "!bg-[#1a2035]"}`}
          data-text="Send a message"
          innerRef={inputBoxRef}
          html={text}
          onChange={handleChange}
        />
        {isStreaming ? (
          <IconButton
            sx={{ position: "absolute", right: "5px" }}
            aria-label="Submit"
            onClick={handleStopStreaming}
          >
            <StopCircleIcon color="primary" />
          </IconButton>
        ) : (
          <IconButton
            sx={{ position: "absolute", right: "5px" }}
            aria-label="Submit"
            onClick={onSubmit}
          >
            <SendIcon color="primary" />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default ContentEdit;
