import React from "react";
import { Button, Typography } from "@material-tailwind/react";
import { Box, Container } from "@mui/material";

import entheoDNAImg from "./../../assets/images/EntheoDNA.png";
import PSImg from "./../../assets/images/PsiloScreen.png";
import EGImg from "./../../assets/images/egpt-logo-social.png";
import EPImg from "./../../assets/images/ep_logo_trippy.png";
import LIMImg from "./../../assets/images/LIMS.png";
import MMTImg from "./../../assets/images/Mushroom times.png";
import { Link } from "react-router-dom";

const SERVICES = [
  {
    title: "Sophisticated AI Models",
    description:
      "Utilize powerful AI models for in-depth research and data analysis.",
    icon: EGImg,
    path: "http://entheo.pro/",
  },
  {
    title: "Custom Chatbot Assistants",
    description:
      "Automate client interactions and support with customizable AI chatbots.",
    icon: EPImg,
    path: "http://entheo.pro/",
  },
  {
    title: "PsiloScreen",
    description:
      "Use AI-powered tools for safe and efficient client screening.",
    icon: PSImg,
    path: "http://psiloscreen.me/",
  },
  {
    title: "EntheoDNA",
    description:
      "Access DNA test kits for personalized insights into sensitivity to various entheogens.",
    icon: entheoDNAImg,
    path: "https://entheo.info/product/entheodna-home-kit/",
  },
  {
    title: "Magic Mushroom Times",
    description: "Stay informed with AI-driven news aggregation and summaries.",
    icon: MMTImg,
    path: "https://magicmushroomtimes.org",
  },
  {
    title: "LIMS",
    description:
      "Streamline testing processes and access comprehensive Certificates of Analysis (COAs).",
    icon: LIMImg,
    path: "https://lims.entheo.info",
  },
];
const UpgradePlan = () => {
  return (
    <>
      <Container
        sx={{
          minHeight: 1,
          pt: { xs: 2, md: 4 },
          pb: { xs: 2, md: 4 },
          textAlign: "center",
        }}
      >
        <Typography variant="h5" color="blue-gray">
          Looking for deeper research or full PDF research documents?
        </Typography>

        <Typography variant="h5" color="blue-gray">
          Try Entheo.Pro Now!
        </Typography>

        <Container
          sx={{
            textAlign: "center",
            pt: { xs: 1, md: 2 },
          }}
        >
          <Typography variant="h4" className="text-[#000080] underline">
            Upgrade to Access
          </Typography>

          <Box
            sx={{
              rowGap: 4,
              columnGap: 4,
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              },

              marginTop: "30px",
            }}
          >
            {SERVICES.map((value) => (
              <div key={value.title}>
                <Link to={value.path}>
                  <img
                    alt={value.title}
                    src={value.icon}
                    className="w-[70px] h-[70px] mx-auto"
                  />
                </Link>

                <Typography variant="h6" className="text-gray-900">
                  {value.title}
                </Typography>

                <Typography className="text-gray-700">
                  {" "}
                  {value.description}{" "}
                </Typography>
              </div>
            ))}
          </Box>
        </Container>
        <div className="flex mt-5">
          <Link to="http://entheo.pro/" className="w-full">
            <Button className="w-full">Ugrade Now</Button>
          </Link>
        </div>
      </Container>
    </>
  );
};

export default UpgradePlan;
