import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Select,
  Option,
  Typography,
} from "@material-tailwind/react";
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import { toast } from "react-toastify";
import * as messages from "../../../services/messages";
import { useTheme } from "@mui/material";

const EditUserDetails = ({ title, user, getUsers }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [userinfo, setUserinfo] = React.useState(user);

  const updateData = useMutation(
    (userinfo) => {
      setOpen(false);
      const res = http.updateUser(
        `user/admin-update`,
        { userinfo: userinfo },
        null
      );
      getUsers.mutate();
      return res;
    },
    {
      onSuccess: () => {
        toast.success("Successfully Updated");
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );
  const errorFunc = (error) => {
    let message;
    switch (error.response.status) {
      case 500: {
        message = error.response.data.message || messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserinfo({ ...userinfo, [name]: value });
  };
  const handleEmailVerify = (value) => {
    setUserinfo({ ...userinfo, emailVerified: value });
  };

  const updateFunc = () => {
    updateData.mutate(userinfo);
  };

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Typography
        as="a"
        href="#"
        // className="text-xs font-semibold text-blue-gray-600"
        className={`text-xs font-semibold ${
          theme.palette.mode === "dark"
            ? "text-[#cdcdcd]"
            : "text-blue-gray-600"
        }`}
        onClick={handleOpen}
      >
        {title}
      </Typography>
      <Dialog
        open={open}
        handler={handleOpen}
        className={theme.palette.mode === "dark" && "!bg-[#282d3d]"}
      >
        <div className="flex items-center justify-between">
          <DialogHeader>
            <Typography
              variant="h6"
              sx={{ textDecoration: "none", color: "grey" }}
            >
              <span
                className={`font-bold ${
                  theme.palette.mode === "dark" && "text-[#e3e3e3]"
                }`}
              >
                Edit User Details
              </span>
            </Typography>
          </DialogHeader>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DialogBody
          divider
          className={`w-full  ${
            theme.palette.mode === "dark" &&
            "!bg-[#282d3d] border-y-[#363b4e] !scroll-red"
          }`}
        >
          <div className="grid gap-6 w-full ">
            <Input
              id="firstName"
              name="firstName"
              onChange={handleInputChange}
              value={userinfo && userinfo.firstName}
              label="First Name"
              className={`!border !border-gray-300 ${
                theme.palette.mode === "dark"
                  ? "text-white"
                  : " placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
              } shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 `}
              labelProps={{
                className: `before:content-none after:content-none ms-2 ${
                  theme.palette.mode === "dark" && "!text-white"
                }`,
              }}
            />
            <Input
              id="lastName"
              name="lastName"
              onChange={handleInputChange}
              value={userinfo && userinfo.lastName}
              label="Last Name"
              className={`!border !border-gray-300 ${
                theme.palette.mode === "dark"
                  ? "text-white"
                  : " placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
              } shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 `}
              labelProps={{
                className: `before:content-none after:content-none ms-2 ${
                  theme.palette.mode === "dark" && "!text-white"
                }`,
              }}
            />
            <Input
              id="email"
              name="email"
              onChange={handleInputChange}
              value={userinfo && userinfo.email}
              label="Email"
              className={`!border !border-gray-300 ${
                theme.palette.mode === "dark"
                  ? "text-white"
                  : " placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
              } shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 `}
              labelProps={{
                className: `before:content-none after:content-none ms-2 ${
                  theme.palette.mode === "dark" && "!text-white"
                }`,
              }}
            />
            <Input
              id="username"
              name="username"
              onChange={handleInputChange}
              value={userinfo && userinfo.username}
              label="Username"
              className={`!border !border-gray-300 ${
                theme.palette.mode === "dark"
                  ? "text-white"
                  : " placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
              } shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 `}
              labelProps={{
                className: `before:content-none after:content-none ms-2 ${
                  theme.palette.mode === "dark" && "!text-white"
                }`,
              }}
            />

            <Select
              onChange={handleEmailVerify}
              value={userinfo && userinfo.emailVerified}
              label="Email Verify"
              labelProps={{
                className: theme.palette.mode === "dark" && "text-white",
              }}
              className={`  ${theme.palette.mode === "dark" && "text-white"}`}
            >
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen}>
            close
          </Button>
          {title !== "Review Profile" && (
            <Button
              variant="gradient"
              // color="green"
              color={theme.palette.mode === "dark" ? "white" : "green"}
              onClick={() => {
                updateFunc();
              }}
            >
              Update Now
            </Button>
          )}
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default EditUserDetails;
