import React from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  // Link
} from "@mui/material";
// import { useNavigate } from 'react-router-dom';

import useStyles from "./styles/styles";
import EPImg from "../../assets/images/Entheology Projects.png";

import Shape from "./components/Shape";
const MissionVision = () => {
  // const navigate = useNavigate();
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7.63vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: { md: "3vw", xs: "0" },
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      my: { xs: "5vw", md: "0" },
      // textAlign: { xs: 'center', md: 'left' },
    },
    buttonSx: {
      width: { md: "22.48vw", xs: "39.95vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: "2vw",
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };
  return (
    <Grid
      container
      className={classes.sectionBox}
      sx={{ background: "#F7F9FF" }}
    >
      <Shape secNumber="3" />
      <Grid
        container
        className={classes.containerGrid}
        sx={{ py: { xs: "16.03vw", md: "3vw" } }}
      >
        <Grid item className={classes.flexCenter} xs={11} md={12}>
          <div className="flex flex-col justify-center items-center">
            <Box
              component="img"
              src={EPImg}
              alt="logo"
              className={`${classes.image} !w-[15rem] !h-auto`}
              sx={{ py: { md: "0", xs: "17.3vw" } }}
            />

            <Typography id="mission" className=" mt-2" sx={clases.header2}>
              Mission and Vision
            </Typography>
          </div>
        </Grid>
        <Container>
          <Grid
            item
            className={classes.flexCenter}
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid item>
              <Box>
                <Typography sx={clases.contentText} textAlign={"center"}>
                  EntheoGPT is a product of{" "}
                  <a
                    href="https://entheo.info/"
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-[#000080] font-bold"
                  >
                    Entheology Project
                  </a>{" "}
                  , a 508(c)(1)(a) non-profit harm-reduction organization that
                  provides the knowledge and tools you need for a safe and
                  transformative journey. Our mission is collecting the vast
                  wealth of entheogenic research around the world using
                  home-grown technologies. Our vision is to empower users with
                  comprehensive, reliable tools and information, ensuring their
                  experiences are both safe and profoundly insightful.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default MissionVision;
