import { call, all, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../constants";

import apiClient from "../apiClient";

import { getAccessToken } from "../../utils/jwt";

import { ServerResponse } from "node:http";

async function shooterhandleUploadFiles(files: any) {
  return apiClient.post("/train/book", files, {
    headers: {
      Authorization: getAccessToken(),
      "Content-type": "multipart/form-data",
    },
  });
}

function* handleUploadFiles(): any {
  return yield takeLatest(
    actionTypes.UPLOAD_FILES_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterhandleUploadFiles,
          action.payload
        );
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}

async function shooterhandleUploadContributeFiles(files: any) {
  return apiClient.post("/train/book/contribute", files, {
    headers: {
      Authorization: getAccessToken(),
      "Content-type": "multipart/form-data",
    },
  });
}

function* handleUploadContributeFiles(): any {
  return yield takeLatest(
    actionTypes.UPLOAD_CONTRIBUTE_FILES_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterhandleUploadContributeFiles,
          action.payload
        );
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}

async function shootergetPDFs(filter: any) {
  let status = [];

  filter.filters.done && status.push("done");
  filter.filters.failed && status.push("failed");
  filter.filters.pending && status.push("pending");
  filter.filters.ready && status.push("ready");
  if (status.length === 0) {
    status = ["done", "failed", "pending", "ready"];
  }

  const [key, sort] = filter.sort.split("|");
  let sorters: any = {};

  sorters[key] = {
    desc: sort === "desc",
  };
  // let sorters = JSON.stringify({
  // 	updated_at: { desc: filter.sort === 'updatedAt|desc' },
  // 	name: { desc: filter.sort === 'name|desc' },
  // });

  let url = `/train/book?cp=${filter.page + 1}&ps=${
    filter.rowsPerPage
  }&filters=${JSON.stringify({
    name: filter?.filters.query,
    status: status,
  })}&sorters=${JSON.stringify(sorters)}`;

  return apiClient.get(url, {
    headers: { Authorization: getAccessToken() },
  });
}

function* getPDFs(): any {
  return yield takeLatest(
    actionTypes.GET_PDFS_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(shootergetPDFs, action.payload);
        yield put({
          type: actionTypes.GET_PDFS_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error: any) {
        console.log(error);
      }
    }
  );
}

async function shootergetMyDocs(filter: any) {
  let status: any[] = [];

  if (filter.filter.statusFilter === "all") {
    status = ["done", "failed", "pending", "ready"];
  } else {
    status.push(filter.filter.statusFilter);
  }

  let sorters: any = {};
  if (filter.orderBy === "privateStatus") {
    sorters["private"] = {
      desc: filter.order === "desc",
    };
  } else {
    sorters[filter.orderBy] = {
      desc: filter.order === "desc",
    };
  }

  let url = `/train/book/mine?cp=${filter.page + 1}&ps=${
    filter.rowsPerPage
  }&filters=${JSON.stringify({
    name: filter?.filter.query,
    status: status,
  })}&sorters=${JSON.stringify(sorters)}`;

  return apiClient.get(url, {
    headers: { Authorization: getAccessToken() },
  });
}

function* getMyDocs(): any {
  return yield takeLatest(
    actionTypes.GET_MYDOCS_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shootergetMyDocs,
          action.payload
        );
        yield put({
          type: actionTypes.GET_MYDOCS_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error: any) {
        console.log(error);
      }
    }
  );
}

async function shooterConvertDocsPrivate(data: {
  item: string[];
  convertPrivate: boolean;
}) {
  return apiClient.put("/train/book/mine/bulk/private", data, {
    headers: { Authorization: getAccessToken() },
  });
}

function* convertDocsPrivate(): any {
  return yield takeLatest(
    actionTypes.CONVERT_DOCUMENTS_PRIVATE_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterConvertDocsPrivate,
          action.payload
        );
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}

async function shooterDeleteDocs(data: { item: string[] }) {
  return apiClient.post("/train/book/mine/bulk/delete", data, {
    headers: { Authorization: getAccessToken() },
  });
}

function* deleteDocs(): any {
  return yield takeLatest(
    actionTypes.DELETE_DOCUMENTS_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterDeleteDocs,
          action.payload
        );
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}

async function shooterregisterWebsiteUrl(url: string) {
  return apiClient.post(
    "/train/website",
    { url },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    }
  );
}

function* registerWebsiteUrl(): any {
  return yield takeLatest(
    actionTypes.REGSITER_URL_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterregisterWebsiteUrl,
          action.payload
        );
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}

async function shooterRegisterContributeWebsiteUrl(url: string) {
  return apiClient.post(
    "/train/website/contribute",
    { url },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    }
  );
}

function* registerCOntributeWebsiteUrl(): any {
  return yield takeLatest(
    actionTypes.REGSITER_CONTRIBUTE_URL_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterRegisterContributeWebsiteUrl,
          action.payload
        );
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}
async function shootergetWebsites(filter: any) {
  let status = [];

  if (filter.filters.status === "all") {
    status = [
      "ready",
      "scraping",
      "scraping failed",
      "scraped",
      "training",
      "training failed",
      "trained",
    ];
  } else {
    status.push(filter.filters.status);
  }

  const [key, sort] = filter.sort.split("|");
  let sorters: any = {};
  sorters[key] = {
    desc: sort === "desc",
  };
  // let sorters = JSON.stringify({
  // 	updated_at: { desc: filter.sort === 'updatedAt|desc' },
  // 	name: { desc: filter.sort === 'name|desc' },
  // });

  let url = `/train/website?cp=${filter.page + 1}&ps=${
    filter.rowsPerPage
  }&filters=${JSON.stringify({
    url: filter?.filters.query,
    status: status,
  })}&sorters=${JSON.stringify(sorters)}`;

  return apiClient.get(url, {
    headers: { Authorization: getAccessToken() },
  });
}

function* getWebsites(): any {
  return yield takeLatest(
    actionTypes.GET_WEBSITES_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shootergetWebsites,
          action.payload
        );
        yield put({
          type: actionTypes.GET_WEBSITES_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error: any) {
        console.log(error);
      }
    }
  );
}

async function shootergetMyWebsites(filter: any) {
  let status: any[] | undefined = [];

  if (filter.filter.statusFilter === "all") {
    status = undefined;
  } else {
    status.push(filter.filter.statusFilter);
  }

  let sorters: any = {};
  if (filter.orderBy === "privateStatus") {
    sorters["private"] = {
      desc: filter.order === "desc",
    };
  } else {
    sorters[filter.orderBy] = {
      desc: filter.order === "desc",
    };
  }

  let url = `/train/website/mine?cp=${filter.page + 1}&ps=${
    filter.rowsPerPage
  }&filters=${JSON.stringify({
    url: filter?.filter.query,
    status: status,
  })}&sorters=${JSON.stringify(sorters)}`;

  return apiClient.get(url, {
    headers: { Authorization: getAccessToken() },
  });
}

function* getMyWebsites(): any {
  return yield takeLatest(
    actionTypes.GET_MYWEBSITES_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shootergetMyWebsites,
          action.payload
        );
        yield put({
          type: actionTypes.GET_MYWEBSITES_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error: any) {
        console.log(error);
      }
    }
  );
}

async function shooterDeleteWebsites(data: { item: string[] }) {
  return apiClient.post("/train/website/mine/bulk/delete", data, {
    headers: { Authorization: getAccessToken() },
  });
}

function* deleteWebsites(): any {
  return yield takeLatest(
    actionTypes.DELETE_WEBSITES_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterDeleteWebsites,
          action.payload
        );
        action.callBack({ status: "success", returnData: data });
      } catch (error: any) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        });
      }
    }
  );
}

export default function* runBlockingCallsExample() {
  yield all([
    getPDFs(),
    handleUploadFiles(),
    handleUploadContributeFiles(),
    getMyDocs(),
    convertDocsPrivate(),
    deleteDocs(),
    registerWebsiteUrl(),
    getWebsites(),
    getMyWebsites(),
    deleteWebsites(),
    registerCOntributeWebsiteUrl(),
  ]);
}
