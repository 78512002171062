import * as actionTypes from "../constants";
import { AllBotMessages } from "../../types/bot-messages";

interface BotState {
  isCreated: boolean;
  end: boolean;
  loading: boolean;
  botMessages: AllBotMessages[];
  answerError: boolean;
  customBot: object;
  botUniqueId: string;
  botDetails: any;
  BotSuggestions: [];
  allGpts: [];
  favGpts: [];
  isBotStreaming: boolean;
  isReportingGPT: boolean;
  isShowingGPT: boolean;
  isReviewingingGPT: boolean;
}

const initialState: BotState = {
  isCreated: false,
  end: true,
  loading: false,
  botMessages: [],
  answerError: false,
  botDetails: JSON.parse(localStorage.getItem("botInfo") || '""'),
  customBot: {},
  botUniqueId: "",
  BotSuggestions: [],
  allGpts: [],
  favGpts: [],
  isBotStreaming: false,
  isReportingGPT: false,
  isShowingGPT: false,
  isReviewingingGPT: false,
};

const reducer = (
  prevState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actionTypes.GET_BOT_DATA_MYC_SUCCESS:
      return {
        ...prevState,
        isCreated: true,
        customBot: action.payload?.data.bot,
      };
    case actionTypes.GET_ALL_GPTS_SUCCESS:
      return {
        ...prevState,
        allGpts: action.payload?.data.records,
      };
    case actionTypes.GET_ALL_FAV_GPTS_SUCCESS:
      let newArr = action.payload?.data.records.bots.map((item: any) => ({
        id: item.id,
        title: item.botName,
        path: `/g/${item.uniqueName}`,
        iconUrl: item.iconUrl,
      }));
      return {
        ...prevState,
        favGpts: [
          {
            key: 1,
            item: newArr,
          },
        ],
      };
    case actionTypes.GET_BOT_DATA_REQUEST_SUCCESS:
      return {
        ...prevState,
        botDetails: action.payload?.data?.bot,
      };
    case actionTypes.GET_BOT_CHAT_HISTORY_REQUEST_SUCCESS:
      let botChatHistory: AllBotMessages[] = [];
      action.payload?.data?.messages.reverse().map((_item: any) => {
        if (_item.role !== "user") {
          botChatHistory.push({
            id: _item.id,
            sender: "bot",
            message: _item.content,
            question: _item.question,
            trigger: _item.trigger ? _item.trigger : "",
            refer: JSON.parse(_item.refer ? _item.refer : "[]"),
          });
        } else {
          botChatHistory.push({
            id: _item.id,
            sender: "human",
            message: _item.content,
          });
        }
        return 0;
      });
      return {
        ...prevState,
        end: action.payload?.data?.end,
        botMessages: botChatHistory,
      };
    case actionTypes.ADD_BOT_MESSAGE:
      return {
        ...prevState,
        loading: true,
        end: prevState.end,
        botMessages: [
          {
            sender: "human",
            message: action.payload?.question,
          },
          ...prevState.botMessages,
        ],
      };
    case actionTypes.ADD_CHAT_BOT_MESSAGE:
      return {
        ...prevState,
        loading: false,
        end: prevState.end,
        botMessages: [
          {
            sender: "bot",
            id: action.payload?.data.messages[1].id,
            message: action.payload?.data.messages[1].content,
            trigger: action.payload?.data.messages[1].trigger
              ? action.payload?.data.messages[1].trigger
              : "",
            question: action.payload?.data.messages[1].question,
          },
          ...prevState.botMessages,
        ],
      };
    case actionTypes.BOT_CHAT_STREAMING:
      return {
        ...prevState,
        isBotStreaming: action.payload,
      };
    case actionTypes.IS_REPORTING_GPT:
      return {
        ...prevState,
        isReportingGPT: action.payload,
      };
    case actionTypes.SHOW_GPT_DETAILS:
      return {
        ...prevState,
        isShowingGPT: action.payload,
      };
    case actionTypes.SHOW_GPT_REVIEW:
      return {
        ...prevState,
        isReviewingingGPT: action.payload,
      };
    default:
      return prevState;
  }
};

export default reducer;
