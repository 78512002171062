import { getJwtToken } from "../lib/token";
import apiClient from "../store/apiClient";
import apiMushPro from "../store/apiMushPro";
import { getAccessToken } from "../utils/jwt";

// set headers
const setHeaders = () => {
  let token = getAccessToken();
  const headers = {
    "Content-type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `${token}`;
  }
  apiClient.defaults.headers.common = headers;
  apiMushPro.defaults.headers.common = headers;
};

// set Quick Response Header
const setQuickResposeHeaders = () => {
  let token = getJwtToken();
  const headers = {
    "Content-type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `${token}`;
  }
  apiClient.defaults.headers.common = headers;
};

// set file headers
const setFileHeaders = () => {
  let token = getAccessToken();
  const headers = {
    "Content-type": "multipart/form-data",
  };
  if (token) {
    headers["Authorization"] = `${token}`;
  }
  apiClient.defaults.headers.common = headers;
};

// global api
const http = {
  async get(endpoint, config, expired, dispatch) {
    return new Promise(async (resolve, reject) => {
      setHeaders();
      try {
        const res = await apiClient.get(`${endpoint}`, config);
        resolve(res);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
          expired(dispatch, true);
        }
        reject(e);
      }
    });
  },
  async post(endpoint, params, config, expired, dispatch) {
    return new Promise(async (resolve, reject) => {
      setHeaders();

      try {
        const req = await apiClient.post(`${endpoint}`, params, config);
        resolve(req);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
          expired(dispatch, true);
        }
        reject(e);
      }
    });
  },
  async filepost(endpoint, params, config, expired, dispatch) {
    return new Promise(async (resolve, reject) => {
      setFileHeaders();
      try {
        const res = await apiClient.post(`${endpoint}`, params, config);
        resolve(res);
        //   return res;
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
          expired(dispatch, true);
        } else {
          //  return e;
          reject(e);
        }
      }
    });
  },
  async put(endpoint, params, config) {
    setHeaders();

    try {
      const req = await apiClient.put(`${endpoint}`, params, config);
      return req;
    } catch (e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.status === "invalid_token"
      ) {
      } else return e;
    }
  },
  async patch(endpoint, params, config) {
    setHeaders();

    try {
      const req = await apiClient.patch(`${endpoint}`, params, config);
      return req;
    } catch (e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.status === "invalid_token"
      ) {
      } else return e;
    }
  },
  async delete(endpoint, config) {
    return new Promise(async (resolve, reject) => {
      setHeaders();
      try {
        const res = await apiClient.delete(`${endpoint}`, config);
        resolve(res);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
        } else {
          reject(e);
        }
      }
    });
  },

  async updateUser(endpoint, body, config) {
    setHeaders();
    try {
      const req = await apiClient.put(`${endpoint}`, body.userinfo, config);
      return req;
    } catch (e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.status === "invalid_token"
      ) {
      } else return e;
    }
  },

  async deleteUser(endpoint, body, config) {
    return new Promise(async (resolve, reject) => {
      setHeaders();
      try {
        const res = await apiClient.delete(
          `${endpoint}`,
          { data: { id: body.userId.id } },
          config
        );
        resolve(res);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
        } else {
          reject(e);
        }
      }
    });
  },

  async deleteFaqs(endpoint, body, config) {
    return new Promise(async (resolve, reject) => {
      setHeaders();
      try {
        const res = await apiClient.delete(
          `${endpoint}`,
          { data: { id: body.userId.id } },
          config
        );
        resolve(res);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
        } else {
          reject(e);
        }
      }
    });
  },
  async deleteDocFrompinecone(endpoint, body, config) {
    return new Promise(async (resolve, reject) => {
      setHeaders();
      try {
        const res = await apiClient.delete(
          `${endpoint}`,
          { data: { id: body.id } },
          config
        );
        resolve(res);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
        } else {
          reject(e);
        }
      }
    });
  },
  async getQuickResponses(endpoint, config, expired, dispatch) {
    return new Promise(async (resolve, reject) => {
      setQuickResposeHeaders();
      try {
        const res = await apiClient.get(`${endpoint}`, config);
        resolve(res);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
          expired(dispatch, true);
        }
        reject(e);
      }
    });
  },
};

export const httpMushPro = {
  async get(endpoint, config, expired, dispatch) {
    return new Promise(async (resolve, reject) => {
      setHeaders();
      try {
        const res = await apiMushPro.get(`${endpoint}`, config);
        resolve(res);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
          expired(dispatch, true);
        }
        reject(e);
      }
    });
  },
  async post(endpoint, params, config, expired, dispatch) {
    return new Promise(async (resolve, reject) => {
      setHeaders();

      try {
        const req = await apiMushPro.post(`${endpoint}`, params, config);
        resolve(req);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
          expired(dispatch, true);
        }
        reject(e);
      }
    });
  },
  async put(endpoint, params, config) {
    setHeaders();

    try {
      const req = await apiMushPro.put(`${endpoint}`, params, config);
      return req;
    } catch (e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.status === "invalid_token"
      ) {
      } else return e;
    }
  },
  async patch(endpoint, params, config) {
    setHeaders();

    try {
      const req = await apiMushPro.patch(`${endpoint}`, params, config);
      return req;
    } catch (e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.status === "invalid_token"
      ) {
      } else return e;
    }
  },
  async delete(endpoint, config) {
    return new Promise(async (resolve, reject) => {
      setHeaders();
      try {
        const res = await apiMushPro.delete(`${endpoint}`, config);
        resolve(res);
      } catch (e) {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.status === "invalid_token"
        ) {
        } else {
          reject(e);
        }
      }
    });
  },
};

export default http;
